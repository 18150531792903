.header {
  margin-bottom: 1.6rem;
}

.contentContainer {
  display: flex;
  flex-direction: row;
  grid-gap: 1.6rem;
  gap: 1.6rem
}

.contentContainer.fullWidth > * {
    flex: 1 1;
  }
