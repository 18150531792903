.container {
  color: var(--color-text-primary);
}

.closed {
  background-color: var(--surface-contrast);
  color: var(--color-text-inverse);
  font-size: var(--font-size--label);
  font-weight: 600;
  border-radius: 0.2rem;
  padding: 0 0.4rem;
  height: 1.6rem;
  min-width: 4.8rem;
  display: inline-block;
  text-align: center;
}
