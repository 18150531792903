.container {
  margin: 1.6rem;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}


.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 48rem;
  text-align: center;
}

.content > div {
  margin-top: 1.6rem;
}

.content span {
  color: var(--color-secondary--100);
}

.icon > svg {
  width: 15rem;
  height: 15rem;
}

