.container {
  position: relative;
  font-size: var(--font-size--body);
  border: none;
  width: 100%;
  color: var(--color-text-secondary);
  padding-left: 0.4rem;
}

.drift {
  color: #049b5e;
}

.firm {
  color: #e2215a;
}

.drift::before {
  content: '';
  position: absolute;

  /* width: 1rem;
  height: 10px; */
  right: -0.8rem;

  /* background: #049B5E; */
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  border-top: 0.8rem solid transparent;
  border-bottom: 0.8rem solid transparent;
  border-right: 0.8rem solid #049b5e;
  -webkit-animation: firm-animation 1.5s;
          animation: firm-animation 1.5s;
}

.firm::before {
  content: '';
  position: absolute;

  /* width: 1rem;
  height: 10px; */
  right: -0.8rem;

  /* background: #049B5E; */
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  border-top: 0.8rem solid transparent;
  border-bottom: 0.8rem solid transparent;
  border-right: 0.8rem solid #e2215a;
  -webkit-animation: drift-animation 1.5s;
          animation: drift-animation 1.5s;
}

@-webkit-keyframes firm-animation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes firm-animation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-webkit-keyframes drift-animation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes drift-animation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fixedWidth {
  width: 5.6rem;
}
