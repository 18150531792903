.container {
  display: flex;
  flex-direction: row;
  border-radius: 0.4rem;
  overflow: hidden;
  align-self: flex-start;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
  height: 3.6rem;
}

.button {
  padding: 0.4rem 1.6rem;
  border-radius: 0;
  border: none;
  background: none;
  color: var(--color-text-button-filters);
  font-weight: var(--font-weight--semi-bold);
  background-color: var(--button-filters)
}

.button:not(:last-child) {
    border-right: 1px solid var(--border-primary);
  }

.button:focus:not(:disabled) {
    box-shadow: none !important;
  }

.button.selected {
    background-color: var(--button-filters-active);
    color: var(--color-text-button-filters-active);
  }

.smallButton {
  padding: 0 0.8rem;
}
