.shortFormPositionInfo {
  width: 100%;
  background: var(--surface-moderate);
  padding: 1.2rem;
  margin-top: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 0.4rem;
  border: none;
  font-size: var(--font-size--label)
}

.shortFormPositionInfo.openState {
    border-radius: 0.4rem 0.4rem 0 0;
  }

.shortFormPositionInfo:focus {
    box-shadow: none !important;
  }

.header {
  display: flex;
  width: 100%;
  grid-row-gap: 0.4rem;
  row-gap: 0.4rem;
  max-width: 800px;
}

.wrap {
  flex-wrap: wrap;
}

.icon {
  margin-right: 1.6rem
}

@media (max-width: 480px) {

.icon {
    display: none
}
  }

.label {
  font-weight: var(--font-weight--semi-bold);
  font-size: var(--font-size--label);
  color: var(--color-text-primary);
  margin-right: 0.8rem;
}

.startItem {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 90%;
  text-align: left;
}

.centerTextAlign {
  text-align: center;
}

.chevronButton {
  background: none;
  border: none;
  display: flex;
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
  align-self: flex-start;
  color: var(--color-text-primary)
}

.chevronButton:hover {
    cursor: pointer;
  }

.chevronButton.expanded {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    transition: -webkit-transform 0.5s ease;
    transition: transform 0.5s ease;
    transition: transform 0.5s ease, -webkit-transform 0.5s ease;
  }

.iconContainer {
  display: flex;
  align-items: center;
}

.minWidth {
  display: flex;
  align-items: center;
  width: 33%;
  color: var(--color-text-secondary);
  font-weight: var(--font-weight--regular);
}

.fullWidth {
  width: 33%;
  margin-bottom: 0.3rem
}

@media (max-width: 500px) {

.fullWidth {
    width: 50%
}
  }

@media (max-width: 400px) {

.fullWidth {
    width: 100%
}
  }

.displayNone {
  display: none;
}

.divider {
  width: 100%;
  height: 0.1rem;
  background-color: var(--surface-moderate);
}

.recentStartWatchReplay {
  flex-direction: row;
  align-items: center;
  border-radius: 0 0 0.4rem 0.4rem;
  padding: 1.2rem;
  background-color: var(--surface-moderate);
  width: 100%;
}

@media (max-width: 480px) {

.replayButton {
    width: 100%
}
  }
