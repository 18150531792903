
.container {
  display: flex;
  flex-direction: column;
  padding: 0 1.5rem;
}

.filterContainer {
  display: flex;
  flex-direction: row;
}

.filterButtonItems {
  margin: 1rem;
}