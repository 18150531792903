html {
  height: 100%;
  font-size: 62.5%;
  overflow: hidden;
}

body {
  margin: 0;
  padding: 0;
  font-size: 1.4rem;
  height: 100vh;
  overflow: auto;
}

#bm-root {
  height: 100%;
  font-family: 'Source Sans Pro', sans-serif;
  font-family: var(--font-family);
  line-height: 1.25;
  line-height: var(--line-height--medium);
  font-weight: 400;
  font-weight: var(--font-weight--regular);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--color-text-primary);
  background-color: var(--background-body);
  overflow: hidden;
}

#bm-grid {
  display: grid;
  grid-template-columns: 1fr auto;
  height: 100%;
  position: relative;
}

#bm-content {
  overflow-x: hidden;
}

#bm-root *, #floating-ui-root * {
    box-sizing: border-box;
  }

#bm-root button, #floating-ui-root button {
    cursor: pointer;
  }

#bm-root .link, #floating-ui-root .link {
    text-decoration: none;
    color: #346c75;
    color: var(--color-secondary--200);
  }

#bm-root input,
  #bm-root textarea,
  #bm-root select,
  #bm-root button,
  #bm-root a,
  #floating-ui-root input,
  #floating-ui-root textarea,
  #floating-ui-root select,
  #floating-ui-root button,
  #floating-ui-root a {
    font-family: 'Source Sans Pro', sans-serif;
    font-family: var(--font-family)
  }

#bm-root input:disabled, #bm-root textarea:disabled, #bm-root select:disabled, #bm-root button:disabled, #bm-root a:disabled, #floating-ui-root input:disabled, #floating-ui-root textarea:disabled, #floating-ui-root select:disabled, #floating-ui-root button:disabled, #floating-ui-root a:disabled {
      cursor: not-allowed;
    }

#bm-root input:focus:not(:disabled), #bm-root textarea:focus:not(:disabled), #bm-root select:focus:not(:disabled), #bm-root button:focus:not(:disabled), #bm-root a:focus:not(:disabled), #floating-ui-root input:focus:not(:disabled), #floating-ui-root textarea:focus:not(:disabled), #floating-ui-root select:focus:not(:disabled), #floating-ui-root button:focus:not(:disabled), #floating-ui-root a:focus:not(:disabled) {
      box-shadow: 0 0 1px 2px #eef0f2;
      box-shadow: 0 0 1px 2px var(--color-grey--50);
      outline: none;
    }

#bm-root h1,
  #bm-root h2,
  #bm-root h3,
  #bm-root h4,
  #bm-root h5,
  #floating-ui-root h1,
  #floating-ui-root h2,
  #floating-ui-root h3,
  #floating-ui-root h4,
  #floating-ui-root h5 {
    margin: 0;
  }

#bm-root h1, #floating-ui-root h1 {
    font-size: 2.2rem;
    font-size: var(--font-size--display-1);
  }

#bm-root h2, #floating-ui-root h2 {
    font-size: 1.6rem;
    font-size: var(--font-size--header-1);
  }

:root {
  --color-grey--50: #eef0f2;
  --color-grey--100: #dbdee3;
  --color-grey--200: #b3b8c2;
  --color-grey--300: #979dab;
  --color-grey--400: #7b8294;
  --color-grey--500: #60697e;
  --color-grey--600: #465169;
  --color-grey--700: #2c3a54;
  --color-grey--800: #232d3f;

  --color-primary--50: #a8dc9c;
  --color-primary--100: #3eb738;
  --color-primary--200: #349a2f;
  --color-primary--300: #2a7c26;
  --color-primary--400: #2c6a26;

  --color-secondary--50: #c0d4d7;
  --color-secondary--100: #3e828c;
  --color-secondary--200: #346c75;
  --color-secondary--300: #29575d;
  --color-secondary--400: #154349;

  --color-medal--gold: #f6ddad;

  --color-black: #191919;

  --font-family: 'Source Sans Pro', sans-serif;

  --font-size--display-1: 2.2rem;
  --font-size--display-2: 2rem;

  --font-size--header-1: 1.6rem;
  --font-size--header-2: 1.4rem;
  --font-size--header-3: 1.2rem;

  --font-size--body: 1.4rem;

  --font-size--label: 1.2rem;

  --font-size--caption: 1.1rem;

  --line-height--small: 1.1;
  --line-height--medium: 1.25;
  --line-height--large: 1.4;
  --line-height--xlarge: 1.6;
  --line-height--title: 1.5;

  --font-weight--bold: 700;
  --font-weight--semi-bold: 600;
  --font-weight--regular: 400;
  --font-weight--light: 300;

  --z-index--badge: 301;
  --z-index--layout: 10;
  --z-index--priority: 100;
  --z-index--modal: 300;
}
