.betItem {
  overflow-y: hidden;
  opacity: 1;
  background-color: var(--core-backgroundModerate);
  border-radius: 0.4rem;
  padding: 1.2rem;
  box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.08)
}

.betItem:not(:last-child) {
    margin-bottom: 0.8rem;
  }

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  line-height: 1;
  color: var(--color-text-primary);
}

.icon {
  position: relative;
  top: 0.2rem;
}

.betType {
  flex: 1 1;
  font-weight: var(--font-weight--semi-bold);
  position: relative;
  left: 0.8rem;
}

.stake {
  font-size: var(--font-size--header-3);
  font-weight: var(--font-weight--semi-bold);
}

.removeButton {
  background: none;
  border: none;
  border-radius: 0.4rem;
  display: flex;
  padding: 0;
  margin-left: 1.2rem;
  color: var(--color-primary)
}

.removeButton:hover {
    cursor: pointer;
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }

.race {
  display: block;
  font-size: var(--font-size--label);
  line-height: var(--line-height--small);
  color: var(--color-text-secondary);
  position: relative;
  left: 3.2rem;
  width: 80%;
}

.selectionsContainer {
  color: var(--color-text-secondary);
  display: flex;
  font-size: var(--font-size--header-3);
  margin: 0.4rem 0.4rem 0.8rem 3.2rem;
  justify-content: space-between;
  line-height: 1.6rem;
  align-items: center;
}

.selections {
  flex-grow: 1;
  margin-left: -0.8rem;
}

.price {
  font-size: var(--font-size--header-3) !important;
  min-width: 30%;
  display: flex;
  justify-content: flex-end;
  margin-left: 0.4rem;
  margin-right: 2.5rem;
}

.stakeInput {
  width: 100%;
}

.return {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  margin: 1.6rem 0.4rem 0;
  font-size: var(--font-size--header-3);
  color: var(--color-text-secondary)
}

.return span {
    margin-left: 0.4rem;
    color: var(--color-text-primary);
    font-weight: var(--font-weight--bold);
  }

.silkContainer {
  margin-right: 0.4rem;
  margin-left: -0.2rem;
}

.headerMessage {
  color: var(--color-alert);
}

.countdown {
  margin-left: 0.4rem;
}

.unresulted {
  border-top: 0.4rem solid var(--color-success);
}

.rejected,
.cancelled,
.refunded {
  border-top: 0.4rem solid var(--color-alert);
}

.partial,
.pending {
  border-top: 0.4rem solid var(--color-attention);
}
