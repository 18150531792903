.container {
  border-radius: 8px;
  margin-bottom: 8px;
}

.scoring {
  display: flex;
  justify-content: flex-end;
  color: var(--color-text-secondary);
  font-weight: 400;
  font-size: 1.2rem;
  line-height: var(--line-height--xlarge);
}

.scoreCell {
  margin-left: 24px;
  width: 40px;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: var(--line-height--xlarge);
  color: var(--color-text-primary);
}

.player {
  display: flex;
  align-items: center;
}
.playerName {
  flex: 1 1;
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.6rem;
  color: var(--color-text-primary);
}
.score {
  display: flex;
}
