.container {
  background-color: #050f21;
  color: var(--color-white);
  display: grid;
  grid-template-columns: auto auto 1fr;
  height: 5.6rem;
  position: relative;
  z-index: var(--z-index--layout)
}

@media (max-height: 726px) {

.container {
    display: none
}
  }

.section {
  flex-shrink: 0;
  position: relative;
  display: flex
}

.section::after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 0.1rem;
    background-color: var(--color-grey--700);
  }

.label {
  flex-shrink: 0;
  padding: 0.8rem 1.6rem;
  margin: auto;
  font-weight: 600
}

@media (max-width: 480px) {

.label {
    display: none
}
  }

.filters {
  flex-shrink: 0;
  padding: 0.8rem 1.6rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  grid-gap: 0.4rem;
  gap: 0.4rem
}

.filters > button {
    -webkit-appearance: none;
            appearance: none;
    background: none;
    border: none;
    color: var(--color-white);
  }

@media (max-width: 480px) {

.filters {
    display: none
}
  }

.filter {
  position: relative;
  height: 100%
}

.filter.selected::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 0.2rem;
    background-color: var(--color-white);
  }

.races {
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1.2rem 0;
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  -ms-scroll-snap-type: x mandatory;
      scroll-snap-type: x mandatory;
  scrollbar-width: none;
  margin-right: 4rem
}

.races::-webkit-scrollbar {
    display: none;
  }

.link {
  border: none;
  background: none;
  text-align: left;
  scroll-snap-align: start;
  flex-shrink: 0;
  color: inherit;
  text-decoration: none;
  display: block;
  position: relative
}

.link:not(:last-child)::after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 0.1rem;
    background-color: var(--border-tertiary);
  }

.race {
  padding: 0 1.6rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  grid-gap: 0.4rem;
  gap: 0.4rem;
}

.track {
  font-weight: 600;
}

.raceNumber {
  font-weight: 600;
  color: var(--color-text-tertiary);
}

.countdown {
  color: var(--color-white);
  text-align: right;
}

.arrows {
  position: absolute;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: var(--surface-contrast);
  display: flex;
  flex-direction: column
}

.arrows::before {
    content: '';
    position: absolute;
    right: 100%;
    top: 0;
    bottom: 0;
    width: 4.8rem;
    background: linear-gradient(90deg, rgba(4, 60, 69, 0) 0%, #030e11 100%);
  }

.arrow {
  flex: 1 1;
  background: none;
  border: none;
  color: var(--color-white);
  padding: 0 0.4rem
}

.arrow:disabled {
    opacity: 0.25;
  }

.separator {
  background-color: var(--color-grey--700);
  height: 0.1rem;
}
