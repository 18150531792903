.container {
  background: var(--core-backgroundTertiary);
  padding: 0 1.6rem 1.6rem;
  font-size: var(--font-size--label);
  color: var(--color-text-secondary);
}

.label {
  font-weight: 600;
}

.runnerContainer {
  display: grid;
  grid-template-columns: 12rem 1fr;
  grid-gap: 0 0.8rem;
  gap: 0 0.8rem;
  padding: 0 2.4rem;
  background: var(--core-backgroundTertiary);
}

.long {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
