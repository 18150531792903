.button {
  display: flex;
  flex-direction: row;
  align-items: center;
  grid-gap: 0.4rem;
  gap: 0.4rem;
  border-radius: 0.4rem;
  border: 0.1rem solid transparent;
}

.theme--primary {
  background-color: var(--button-primary);
  border-color: var(--button-primary);
  color: var(--color-text-button-primary);
}

.theme--secondary {
  border-color: var(--border-secondary);
  background-color: var(--button-secondary);
  color: var(--color-text-button-secondary)
}

.theme--secondary:disabled {
    border-color: var(--border-primary);
    background-color: var(--button-secondary-disabled);
  }

.theme--stroke {
  border-color: var(--color-white);
  background-color: transparent;
  color: var(--color-white);
}

.size--medium {
  padding: 0.8rem 1.6rem;
}

.size--small {
  padding: 0.4rem 0.8rem;
}
