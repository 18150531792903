.container {
  cursor: pointer;
}

.odds {
  display: flex;
}

.oddsbutton {
  flex: 1 1;
}

.odds > button:not(:last-of-type) {
  margin-right: 8px;
}
