.container {
  padding: 0 1.6rem 1.6rem;
  color: var(--content-tertiary)
}

@media (max-width: 480px) {

.container {
    padding-left: 0;
    padding-right: 0
}
  }

.leagueContainer {
  margin-top: 1.3rem;
}