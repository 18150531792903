.container {
  display: flex;
  align-items: center;
  padding: 1rem 1.7rem;
  min-width: 0;
  background-color: var(--color-grey--50);
  border: none;
  border-radius: 0.4rem
}

@media (max-width: 480px) {

.container {
    padding: 0;
    width: 6.4rem;
    height: 4rem
}
  }

.container:hover {
    cursor: pointer;
  }

.container.selected {
    background-color: var(--button-odds-active);
    color: var(--color-text-button-odds-active);
    border: 1px solid var(--button-odds-active);
  }

.reverse {
  flex-direction: row-reverse;
}

.jersey {
  margin-right: 1.3rem;
}

.teamName {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  overflow: hidden;
}

.label {
  font-weight: var(--font-weight--regular);
  font-size: var(--font-size--label);
  line-height: 1.6rem;
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.odds {
  font-weight: var(--font-weight--semi-bold);
  font-size: var(--font-size--body);
  line-height: 1.6rem;
}
