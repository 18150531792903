.container {
  display: flex;
  align-items: center;
  overflow: hidden;
}

.jerseyContainer {
  width: 22px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.jerseyContainer img {
  max-width: 100%;
  max-height: 100%;
}

.teamName {
  font-weight: var(--font-weight--semi-bold);
  font-size: var(--font-size--body);
  line-height: var(----line-height--title);
  margin-left: 13px;
  margin-right: 8px;
  min-width: 0;
  overflow: hidden;
}

.teamName span {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
}