.container {
  display: flex;
  padding: 12px;
}

.icon {
  margin-right: 10px;
}

.details {
  flex: 1 1;
}

.badge {
  text-align: left;
}

.title {
  display: flex;
  font-size: var(--font-size--label);
  font-weight: var(--font-weight--semi-bold);
}

.infoIcon {
  color: var(--color-text-primary);
}

.combos {
  font-size: var(--font-size--label);
  font-weight: var(--font-weight--regular);
}

.stake {
  width: 125px;
}
