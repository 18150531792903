.very-small {
  width: 0.6rem;
  height: 0.6rem;
}

.extra-small {
  width: 0.8rem;
  height: 0.8rem;
}

.semi-small {
  width: 1.2rem;
  height: 1.2rem;
}

.small {
  width: 1.6rem;
  height: 1.6rem;
}

.medium {
  width: 2.4rem;
  height: 2.4rem;
}

.large {
  width: 3.2rem;
  height: 3.2rem;
}
