.betItem {
  overflow-y: hidden;
  opacity: 1;
  background-color: var(--core-backgroundModerate);
  border-radius: 0.4rem;
  padding: 0.8rem;
  box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.08)
}

.betItem:not(:last-child) {
    margin-bottom: 0.8rem;
  }

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: var(--color-text-primary);
}

.flexColumn {
  display: flex;
  flex-direction: column;
}

.flexRow {
  display: flex;
}

.icon {
  position: relative;
  top: 0.2rem;
}

.betType {
  flex: 1 1;
  font-weight: var(--font-weight--semi-bold);
  position: relative;
  left: 0.8rem;
}

.stake {
  font-size: var(--font-size--header-3);
  font-weight: var(--font-weight--semi-bold);
}

.removeButton {
  background: none;
  border: none;
  border-radius: 0.4rem;
  padding: 0;
  margin-left: 1.2rem;
  margin-top: 0.2rem;
  display: flex;
  color: var(--color-primary)
}

.removeButton:hover {
    cursor: pointer;
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }

.race {
  display: block;
  font-size: var(--font-size--label);
  line-height: var(--line-height--small);
  color: var(--color-text-secondary);
  position: relative;
  left: 0.8rem;
}

.selections {
  margin: 0.8rem 0;
  padding-bottom: 0.8rem;
  font-size: var(--font-size--label);
  line-height: var(--line-height--small);
  color: var(--color-text-secondary);
  margin-left: 3.2rem;
}

.stakeInput {
  width: 100%;
}

.calcContainer {
  position: absolute;
  bottom: 9rem;
  left: 0;
  width: 100%;
  background: var(--color-grey--800);
  color: white;
}

.numpadCalc {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  margin: 1rem
}

.numpadCalc > * {
    width: calc((100% / 4) - 0.8rem);
    margin: 0.4rem;
  }

.buttonBet {
  height: 4rem;
}

.numpadHeader {
  background: var(--color-grey--800);
  display: flex;
  flex-flow: row wrap;
  align-items: left;
  color: white;
  padding: 0.8rem 0.5rem 0 1.5rem;
}
