.button {
  font-size: var(--font-size--body);
  border-radius: 0.4rem;
  border: 1px solid transparent;
  transition: all 0.2s ease-in-out;
  padding: 0.8rem 1.6rem;
  font-weight: 600;
  box-shadow: 0 2px 8px 1px rgba(0, 0, 0, 0.08);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: var(--color-text-button-filters);
  background-color: var(--button-filters)
}

.button:disabled {
    opacity: 0.75;
    border: 1px solid;
  }

.button:not(:disabled):hover {
    cursor: pointer;
    border-color: var(--border-secondary);
  }

.button[data-selected='true'] {
    background-color: var(--button-filters-active);
    color: var(--color-text-button-filters-active);
  }

.size--default {
  height: auto;
}

.size--small {
  min-height: 2.4rem;
  padding: 0.2rem 0.8rem;
}

.size--medium {
  min-height: 3.2rem;
}

.size--large {
  min-height: 4.3rem;
  padding: 1.2rem 2.4rem;
}

.buttonContent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  grid-gap: 0.8rem;
  gap: 0.8rem;
}

.iconContainer {
  display: flex;
}

.marginRight {
  margin-right: 0.6rem;
}

.marginLeft {
  margin-left: 0.6rem;
}
