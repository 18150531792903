.grid {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 0 1.6rem;
  gap: 0 1.6rem;
  background-color: var(--core-backgroundPrimary);
  -webkit-filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.08));
          filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.08));
  border-radius: 4px;
  margin: 4rem 0 1.6rem;
  padding: 0 1.6rem;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.label {
  display: flex;
  flex-direction: row;
  align-items: center;
  top: -1.6rem;
  position: relative;
  height: 0
}

.label.selected {
    color: var(--color-text-primary);
  }

.label.sortable:hover {
    cursor: pointer;
  }

.center {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.separator {
  grid-column: 1 / -1;
  background-color: var(--border-primary);
  height: 0.1rem;
}

.sectionRow {
  grid-column: 1 / -1;
}

.hiddenOnDesktop {
  display: none
}

@media (max-width: 1200px) {

.hiddenOnDesktop {
    display: flex
}
  }

@media (max-width: 1200px) {

.hiddenOnMobile {
    display: none
}
  }

.priceContainer {
  grid-column: 1 / -1;
  position: relative;
}

.price {
  position: absolute;
  right: 0;
  top: 0.8rem;
  font-weight: 600;
}

.exoticsAllButton {
  padding: 0.2rem 0.8rem;
  min-width: 4.8rem;
}

.exoticsButton {
  margin-top: 2rem;
  padding: 0.4rem 0.8rem;
}

.boxedButton {
  justify-self: flex-end
}

@media (max-width: 480px) {

.boxedButton {
    justify-self: flex-start
}
  }

.priority {
  top: -4.8rem;
}

.leftAlign {
  text-align: left;
}
