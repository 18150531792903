.input {
  height: 0;
  width: 0;
  visibility: hidden;
}

.label {
  cursor: pointer;
  text-indent: -9999px;
  width: 4.8rem;
  height: 2.4rem;
  background: var(--surface-bold);
  display: block;
  border-radius: 100px;
  position: relative;
}

.label::after {
  content: '';
  position: absolute;
  top: 0.2rem;
  left: 0.2rem;
  width: 2rem;
  height: 2rem;
  background: #fff;
  border-radius: 50%;
  transition: 0.3s;
}

.input:checked + .label {
  background: var(--button-primary)
}

.input:checked + .label::after {
    left: calc(100% - 0.2rem);
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%)
  }

.input:checked + .label::after:active {
      width: 130px;
    }
