:root {
  --featured-race_surface--contrast: #232d3f;
}

.header {
  display: grid;
  grid-template-columns: 1fr 5.4rem 1fr;
  color: var(--color-white);
}

.description {
  text-align: right;
  background-color: #232d3f;
  background-color: var(--featured-race_surface--contrast);
  padding: 0.8rem 1.6rem 0.8rem 0.8rem;
}

.container {
  box-shadow: 0 0.2rem 4px rgba(0, 0, 0, 0.08);
  border-radius: 0.4rem;
  min-width: 40rem;
  overflow: hidden;
  scroll-snap-align: start
}

@media (max-width: 480px) {

.container {
    min-width: 100%
}
  }

.container.THOROUGHBRED .header {
      background-color: var(--racing_thoroughbred--200);
    }

.container.THOROUGHBRED .triangle {
      background: linear-gradient(
        -45deg,
        #232d3f 50%,
        var(--racing_thoroughbred--200) 50%
      );
      background: linear-gradient(
        -45deg,
        var(--featured-race_surface--contrast) 50%,
        var(--racing_thoroughbred--200) 50%
      );
    }

.container.HARNESS .header {
      background-color: var(--racing_harness--200);
    }

.container.HARNESS .triangle {
      background: linear-gradient(
        -45deg,
        #232d3f 50%,
        var(--racing_harness--200) 50%
      );
      background: linear-gradient(
        -45deg,
        var(--featured-race_surface--contrast) 50%,
        var(--racing_harness--200) 50%
      );
    }

.container.GREYHOUND .header {
      background-color: var(--racing_greyhound--200);
    }

.container.GREYHOUND .triangle {
      background: linear-gradient(
        -45deg,
        #232d3f 50%,
        var(--racing_greyhound--200) 50%
      );
      background: linear-gradient(
        -45deg,
        var(--featured-race_surface--contrast) 50%,
        var(--racing_greyhound--200) 50%
      );
    }

.track {
  display: grid;
  grid: 'type track' auto 'type raceNumber' auto / auto 1fr;
  grid-column-gap: 0.8rem;
  -webkit-column-gap: 0.8rem;
          column-gap: 0.8rem;
  padding: 0.8rem 0.8rem 0.8rem 1.6rem;
}

.trackName {
  grid-area: track;
  font-weight: var(--font-weight--bold);
}

.raceNumber {
  grid-area: raceNumber;
}

.content {
  background-color: var(--color-white);
  padding: 1.2rem 1.6rem 0;
}

.grid {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 0.8rem;
  gap: 0.8rem;
}

.label {
  font-weight: var(--font-weight--bold);
  font-size: 1.2rem;
  line-height: 1.8rem;
  color: var(--color-text-primary)
}

.label.center {
    text-align: center;
  }

.price {
  display: flex;
  align-items: center;
  justify-content: center;
}

.separator {
  grid-column: 1 / 3;
  height: 0.1rem;
  background-color: var(--border-primary);
}

.button {
  padding: 0.4rem;
}

.link {
  display: block;
  text-align: center;
  padding: 1.6rem;
  background-color: var(--color-white);
}
