.container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.competition {
  color: var(--color-text-primary);
}

.features {
  flex-direction: row;
  display: flex;
  flex: 1 1;
  align-items: center;
  margin-left: 0.8rem;
}

.verticalSeperator {
  margin: 0 0.4rem;
  width: 0.2rem;
  background-color: var(--color-text-secondary);
  height: 1.6rem;
}

.markets {
  color: var(--color-text-link);
  font-weight: bold;
}

.icon {
  width: 2.4rem;
  height: 2.4rem;
}

.icon * {
  fill: var(--color-text-primary);
}
.timeMarket {
  display: flex;
  align-items: center;
  color: var(--color-text-primary);
}

.live {
  display: flex;
  align-items: center;
}

.live svg {
  width: 43px;
  height: 16px;
}
