.container {
  position: relative
}

.container.noSelections {
    display: none;
  }

.container.hasError {
    padding-top: 4.8rem;
  }

.toast {
  position: absolute;
  bottom: calc(100% + 1.6rem);
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  background-color: var(--surface-contrast);
  color: var(--color-text-inverse);
  padding: 0.8rem 3.2rem;
  border-radius: 0.4rem
}

.toast:hover {
    cursor: pointer;
  }

.content {
  background-color: var(--brand-background-bottomSelector);
  padding: 0.8rem 1.6rem;
  border-bottom-left-radius: 0.4rem;
  border-bottom-right-radius: 0.4rem;
}

.builder {
  position: relative;
  color: var(--color-white)
}

.builder.fixed {
    position: fixed;
    bottom: 0
  }

.builder.fixed > .content {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

.header {
  background-color: var(--brand-background-bottomSelectorHeader);
  padding: 0.8rem 1.6rem;
  border-top-left-radius: 0.4rem;
  border-top-right-radius: 0.4rem;
  font-weight: 600;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  border: none;
  color: inherit;
}

.combos {
  font-weight: 400;
}

.selectionsIcon {
  -webkit-transform: rotate(0);
          transform: rotate(0);
}

.selections {
  display: none;
  margin-bottom: 0.8rem;
}

.selection {
  display: flex;
  flex-direction: row;
  align-items: center;
  grid-gap: 0.4rem;
  gap: 0.4rem;
}

.ordinal {
  font-weight: 600;
}

.actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.showSelections .selectionsIcon {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }

.showSelections .selections {
    display: flex;
    flex-direction: column;
    grid-gap: 0.4rem;
    gap: 0.4rem;
    margin: 0.8rem 0;
  }
