.betslipContainer {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  width: 29.6rem;
  box-shadow: 0 2px 8px 1px rgba(0, 0, 0, 0.08);
  background-color: var(--background-betslip)
}

@media (max-width: 1024px) {

  .betslipContainer {
    display: none;
    width: 100%;
    background-color: var(--background-betslip)
}

    .betslipContainer.open {
      display: block;
      position: fixed;
      top: 0 !important;

      /* z-index: 1000; */
      height: 100vh;
    }
  }
