
.container {
  display: flex;
  flex-direction: column;
  padding: 0 15px;
}

.filterContainer {
  display: flex;
  flex-direction: row;
}

.filterButtonItems {
  margin: 10px;
}



.placeholder {
  width: 100%;
  height: 50px;
  background-color: rgba(255,255,255,1);
}