.container {
  padding: 1.6rem;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.6rem;
}

.filters {
  display: flex;
  flex-flow: row wrap;
  grid-gap: 0.8rem;
  gap: 0.8rem;
}

.section {
  margin: 1.6rem 0;
}

.title {
  display: flex;
  align-items: center;
  grid-gap: 0.4rem;
  gap: 0.4rem;
  color: var(--color-text-primary);
  font-size: var(--font-size--label);
  font-weight: var(--font-weight--bold);
}

.sectionHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.legend {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  cursor: pointer;
  grid-gap: 0.8rem;
  gap: 0.8rem;
  line-height: var(--line-height--small);
  font-size: var(--font-size--label);
  color: var(--color-text-primary)
}

.legend > .tooltip {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 0.4rem;
    color: var(--color-text-primary);
  }

@media (max-width: 1024px) {

.legend {
    margin-top: 0.4rem
}
  }

@media (max-width: 480px) {

.hideMobile {
    display: none
}
  }
