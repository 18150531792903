.popup {
  display: flex;
  flex-direction: column;
  background-color: var(--core-backgroundPrimary);
  overflow: auto;
  border-radius: 0.4rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
  min-width: 8rem;
}

.button {
  text-align: left;
  color: var(--color-text-button-filters);
  background-color: var(--button-filters);
  border: none;
  padding: 0.8rem 1.6rem;
  display: block;
  font-weight: 600;
  font-size: var(--font-size--body)
}

.button:hover {
    background-color: var(--button-filters-active);
    color: var(--color-text-button-filters-active);
  }
