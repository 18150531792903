
  @media (max-width: 480px) {

.container {
    padding: 0;
    padding-top: 1.6rem
}
  }

.filters {
  padding: 15px 0;
  margin: 0 1.6rem;
  border-top: var(--border-primary) 1px solid;
  background-color: var(--core-backgroundPrimary);
  border-radius: 0 0 4px 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08)
}

@media (max-width: 480px) {

.filters {
    padding: 15px 0;
    border-radius: 0;
    margin: 0 0 16px
}
  }

.tabs {
  margin: 15px 0;
}

.content {
  padding: 16px;
  width: 100%;
  height: 100vh
}

@media (max-width: 480px) {

.content {
    padding: 0
}
  }

.content iframe {
  min-width: 100%;
  min-height: 100%;
  border: none;
}