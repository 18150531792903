.link {
  text-decoration: none;
  color: var(--color-grey--800);
}

.container {
  padding: 11px 19px;
  background-color: var(--core-backgroundPrimary);
  display: flex;
  align-items: center;
  border: none;
  width: 100%;
  text-align: left;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1;
  box-shadow: var(--shadow-c1-center);
  color: var(--content-header-primary);
  margin-bottom: 1rem;

  /* TODO Talk to Triastan about the shadow */
}

.container::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 4px;
  background-color: var(--brand-background-tabPrimaryActive);
  content: '';
}

.icon {
  height: 1.8rem;
  width: 1.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon * {
  fill: var(--color-text-primary);
}

.label {
  margin-left: 1.1rem;
  font-weight: var(--font-weight--bold);
  font-size: var(--font-size--body);
  line-height: 1.6rem;
  flex: 1 1;
  color: var(--color-text-primary);
}
