.container {
  padding-bottom: 1.6rem;
  color: var(--color-text-primary);
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 1.6rem;
  color: var(--color-text-primary);
}

.filters {
  display: flex;
  align-items: center;
  flex-flow: row wrap;
  grid-gap: 0.8rem;
  gap: 0.8rem;
}

.trackFilter {
  padding: 0.4rem;
  border-radius: 0.4rem;
  box-shadow: 0 2px 8px 1px rgba(0, 0, 0, 0.08);
  border: 1px solid var(--core-backgroundPrimary)
}

.trackFilter:hover {
    cursor: pointer;
  }

.trackSelect {
  height: 3.6rem;
  min-width: 12rem
}

.trackSelect select {
    height: 100%;
  }
