.container {
  background-color: var(--core-backgroundPrimary);
  display: flex;
  flex-direction: row;
  padding: .9rem 1.6rem;
  align-items: center;
  border-bottom: solid 1px var(--border-primary);
}

.link {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1 1;
  text-decoration: none;
  color: var(--color-grey--800);
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: .8rem;
}

.icon > svg{
  width: 2.4rem;
  height: 2.4rem;
}

.icon svg > * {
  fill: var(--color-text-primary);

  /* TODO Change this??? */
}

.label {
  flex: 1 1;
  font-weight: var(--font-weight--semi-bold);
  color: var(--color-text-primary);
}
