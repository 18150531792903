.bets {
  list-style-type: none;
  padding: 8px 0;
  display: flex;
  height: 56px;
  border-bottom: 1px solid var(--border-primary);
}

.teamsName {
  align-self: center;
  flex: 1 1;
}
