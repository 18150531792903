/* stylelint-disable selector-class-pattern, custom-property-pattern, declaration-block-no-duplicate-properties */

.inputContainer {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  color: var(--color-text-primary);
  border-radius: 0.4rem;
}

.input::-webkit-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--color-text-tertiary);
  opacity: 1; /* Firefox */
}

.input:-ms-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--color-text-tertiary);
  opacity: 1; /* Firefox */
}

.input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--color-text-tertiary);
  opacity: 1; /* Firefox */
}

.input {
  border-radius: 0.4rem;
  background-color: rgba(255,255,255,1);
  padding: 0.4rem 1.6rem;
  color: var(--color-text-primary);
  font-size: var(--font-size--header-1);
  width: 100%;
  border: solid 1px var(--color-grey--100);
  text-align: right
}

.input:focus {
    color: var(--color-text-primary);
    box-shadow: none;
  }

.input::-webkit-outer-spin-button,
.input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
            appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

.input[type=number] {
    -webkit-appearance:textfield;
            appearance:textfield; /* Firefox */
}

.currencyCode {
  position: absolute;
  left: 0.4rem;
  top: 0.8rem;
  line-height: 1.15;
}

.inputPrefix {
  position: relative;
  display: flex;
  align-items: center;
  max-width: 30rem;
  width: 100%;
  background: #fff;
  border: 0.1rem solid #a0a0a0;
  border-radius: 0.4rem;
  padding-left: 0.5rem;
  overflow: hidden;
  font-family: sans-serif;
}

.inputPrefix .currencyCode {
  font-weight: 300;
  color: var(--color-text-primary);
  font-size: 1.4rem;
  line-height: 1;
}

.inputPrefix input {
  flex-grow: 1;
  font-size: 1.4rem;
  background: #fff;
  border: none;
  outline: none;
  padding: 0.3rem;
}

.inputPrefix[focus-within] {
  border-color: #777;
}

.inputPrefix:focus-within {
  border-color: #777;
}

.iconContainer {
  cursor: pointer;
  position: absolute;
  display: flex;
  right: 0.8rem;
  color: var(--color-text-primary)
}

.iconContainer :hover {
    color: var(--color-text-secondary);
  }

.icon {
  height: 1.2rem;
  width: 1.2rem;
}
