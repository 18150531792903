.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: var(--color-text-primary);
}

.header {
  padding: 0.8rem 1.6rem;
  z-index: var(--z-index--layout);
  display: flex;
  flex-direction: row;
  align-items: center;
  line-height: 1.2;
}

.betslipTitle {
  font-size: var(--font-size--display-2);
  font-weight: var(--font-weight--bold);
}

.chevron {
  display: none
}

@media (max-width: 1024px) {

.chevron {
    display: flex;
    margin-right: 0.4rem
}
  }

.chevron:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }

.betslip {
  flex: 1 1;
  overflow-x: auto;
}

.footer {
  background-color: var(--core-backgroundPrimary);
  padding: 1.6rem;
  box-shadow: 0 -1px 4px 1px rgba(0, 0, 0, 0.08);
  z-index: var(--z-index--layout);
  color: var(--color-text-primary);
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.label {
  color: var(--color-text-primary);
}

.actions {
  display: grid;
  grid-gap: 0.8rem;
  grid-template-columns: 1fr 1fr;
  margin-top: 0.8rem;
}

.stakeContainer {
  border: 0.1rem solid var(--color-grey--400);
  border-radius: 0.4rem;
  padding: 0.8rem;
  text-align: right;
  margin-bottom: 0.8rem;
}

.stake {
  line-height: var(--line-height--title);
  font-size: var(--font-size--display-1);
}

.caret {
  margin-right: -0.8rem;
  color: var(--color-text-primary);
}

.buttonBetPlacement {
  background-color: var(--button-place-bet);
  color: var(--color-text-button-place-bet);
}

.disabled {
  background-color: var(--button-place-bet-disabled);
  color: var(--color-text-button-place-bet);
}

/* move to buttons -- maybe */
.clearBets {
  border: var(--border-secondary);
  color: var(--color-text-button-secondary);
}
