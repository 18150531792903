.container {
  border-radius: 0.4rem;
  background-color: var(--color-white);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
  padding: 1.2rem 1.6rem;
  flex: 1 1;
  min-width: 28rem;
  display: flex;
  flex-direction: column;
  scroll-snap-align: start
}

.container:not(:last-child) {
    margin-right: 1.6rem;
  }

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between
}

.header > * {
    flex-shrink: 0;
  }

.header.center {
    justify-self: center;
  }

.icon {
  color: var(--color-white);
}

.raceDetails {
  flex: 1 1;
  margin: 0 0.8rem;
  overflow: hidden;
}

.raceName {
  display: block;
  font-weight: var(--font-weight--bold);
  font-size: var(--font-size--body);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-decoration: none;
  color: var(--color-black);
  line-height: var(--line-height--medium);
}

.runners {
  padding: 0.8rem 0;
  flex: 1 1;
}

.grid {
  display: grid;
  grid-template-columns: 1fr auto
}

.grid > .header {
    font-size: var(--font-size--caption);
    color: var(--color-grey--600);
  }

.grid > .separator {
    border-bottom: 0.1rem solid var(--color-grey--100);
    grid-column: 1 / 3;
    margin: 0.8rem 0;
  }

.oddsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.footer {
  text-align: center;
}

.viewRaceCard {
  display: block;
  font-size: var(--font-size--body);
  font-weight: var(--font-weight--semi-bold);
  text-decoration: none;
  color: #3e818c;
}

.countdown {
  font-size: var(--font-size--label);
}
