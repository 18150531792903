.container {
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: var(--z-index--modal)
}
@media (max-width: 480px) {
  .container {
    align-items: flex-end
}
  }