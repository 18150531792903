.container {
  box-shadow: 0 0.2rem 4px rgba(0, 0, 0, 0.08);
  border-radius: 0.4rem;
  overflow: hidden;
  scroll-snap-align: start;
  background-color: var(--color-white);
  padding: 1.2rem 1.6rem;
  min-width: 28rem
}

.container::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 0.4rem;
  }

@media (max-width: 480px) {

.container {
    min-width: 100%
}
  }

.separator {
  height: 0.1rem;
  background-color: var(--border-primary);
  margin: 0.8rem 0;
}

.track {
  display: flex;
  align-items: center;
  font-size: var(--font-size--label);
  margin: 0.8rem 0
}

.track .trackName {
    margin-left: 0.4rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

.track .status {
    margin-left: auto;
    text-align: right;
    font-weight: var(--font-weight--semi-bold);
  }

.prices {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 0.8rem;
  gap: 0.8rem;
}
