.container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding-top: 0.8rem;
  margin: -0.2rem
}

.container > * {
    width: calc((100% / 4) - 0.4rem);
    margin: 0.2rem;
  }

.button {
  background-color: var(--button-numpad);
  border: none;

  /* color: var(--color-grey--800);
  padding: 0.8rem;
  height: 3.2rem;
  font-size: var(--font-size--label);
  border: 1px solid var(--color-grey--200);

  &:hover {
    border-color: var(--color-secondary--200);
    background-color: var(--color-secondary--200);
    color: var(--color-white);
  } */
}
