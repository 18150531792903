.container {
  display: flex;
  flex-direction: column;
  padding-top: 2rem;
  cursor: pointer;
}

.team {
  display: flex;
  margin: .6rem 0;
}

.homeTeamWrapper {
  flex: 1 1;
  position: relative;
  align-items: center;
  display: flex;
  overflow: hidden;
}

.homeTeam.teamName {
  height: 100%;
}

.teamName {
  flex: 1 1;
}


.odds {
  flex: 1 1;
  display: flex;
}

.homeTeamOdds {
  flex: 1 1;
  display: flex;
  font-weight: var(--font-weight--bold);
  font-size: var(--font-size--body);
  line-height: 1.6rem;
  color: var(--color-grey--400);
  text-transform: uppercase;
}

.oddsWithBetType {
  flex: 1 1;
  display: flex;
  position: relative;
}

.betTypeWrapper {
  position: absolute;
  top: -20px;
  left: 0;
  right: 0;
  text-align: center;
}

.homeTeamOdds div:not(:last-of-type) {
  margin-right: 8px;
}


.odds button {
  width: 100%;
}
.homeTeamOdds button {
  width: 100%;
}

.odds button:not(:last-of-type) {
  margin-right: 8px;
}

.seperator {
  position: absolute;
  bottom: -12px;
  left: 0;
  right: 16px;
}