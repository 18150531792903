
.teamNamesContainer {
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.teamNames {
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
}

.teamName {
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: var(--color-text-primary);
  overflow: hidden;
  min-width: 0;
}

.teamName div {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.seperatorText {
  margin: 0 4px;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: var(--color-text-primary);
}

.icon {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.icon * {
  fill: var(--color-text-primary);
}