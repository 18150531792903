.container {
  color: var(--color-text-primary);
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.races {
  margin-top: 1.6rem;
  display: flex;
  flex-direction: row;
  grid-gap: 0.8rem;
  gap: 0.8rem;
  overflow: auto;
}

.raceNumber {
  flex-shrink: 0;
  width: 3.2rem;
  height: 3.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--button-race-nav-next);
  color: var(--color-text-primary);
  border-radius: 0.4rem;
  border: none
}

.raceNumber.closed {
    background-color: var(--button-race-nav-previous);
    color: var(--color-text-tertiary);
  }

.raceNumber.selected {
    background-color: var(--brand-background-togglesActive);
    border-color: var(--brand-background-togglesActive);
    color: var(--color-text-inverse);
  }

.raceNumber:hover {
    cursor: pointer;
    background-color: var(--button-primary-hover);
    border-color: var(--button-primary-hover);
    color: var(--color-text-button-alt);
  }

.information {
  margin-top: 1.6rem
}

.information > .primary {
    font-weight: 600;
    display: flex;
    flex-direction: row;
    justify-content: space-between
  }

.information > .primary > .name {
      flex: 1 1;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

.information > .content {
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-gap: 0.8rem;
    gap: 0.8rem
  }

.information > .content > *:not(:last-child) {
      position: relative;
      padding-right: 0.8rem
    }

.information > .content > *:not(:last-child)::after {
        content: '';
        position: absolute;
        right: 0;
        top: 50%;
        -webkit-transform: translateY(-50%);
                transform: translateY(-50%);
        height: 1.2rem;
        background-color: var(--border-tertiary);
        width: 0.1rem;
      }

.label {
  font-weight: 600
}

@media (max-width: 480px) {

.label {
    display: none
}
  }

.country {
  display: flex;
  flex-direction: row;
  align-items: center;
  grid-gap: 0.4rem;
  gap: 0.4rem;
}

.descriptionButton {
  border: none;
  background: transparent;
  color: inherit;
  font-weight: 600;
  text-decoration: underline
}

.descriptionButton.selected > .icon {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
