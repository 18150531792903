.container {
  padding: 0.4rem 0.8rem;
  display: flex;
  align-items: center
}

.container span {
    margin-left: 0.4rem;
  }

.placed {
  background-color: var(--color-success);
  color: white;
}

.rejected {
  background-color: var(--color-alert);
  color: white;
}

.partial {
  background-color: var(--color-white);
  color: var(--color-attention);
  border-top: 0.1rem var(--color-grey--100) solid;
}