.modal {
  /* background-color: var(--core-backgroundContrast); */
  background-color: white;
  padding: 24px 16px 35px 16px;
  border-radius: 4px;
}

.modalOverlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  top:50%;
  left: 50%;
  -webkit-transform: translateY(-50%) translateX(-50%);
          transform: translateY(-50%) translateX(-50%);
}

.phoneNumber {
  font-weight: 700;
  font-size: 2rem;
  line-height: 2.4rem;
}

.fastCode {
  margin-top: 8px;
  margin-bottom: 25px;
}



