.checkboxWrapper {
  display: block;
}

.checkbox {
  position: relative;
  width: 2.4rem;
  height: 2.4rem;
  border-radius: 0.4rem;
  border: 0.2rem solid var(--border-primary);
  background-color: var(--core-backgroundContrast);
  transition: all 0.2s ease;
}

.icon {
  transition: opacity 0.2s ease;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  margin: 0 auto;
}

.input {
  position: absolute;
  border: none;
  width: 0;
  height: 0;
  outline: 0;
  -webkit-appearance: none;
          appearance: none;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: -1
}

.input:checked + .checkbox {
      background-color: var(--background-toggles-active);
      border-color: var(--background-toggles-active);
    }

.input:disabled + .checkbox {
      opacity: 0.5;
      cursor: not-allowed;
    }

.input:not(:disabled):hover + .checkbox {
      cursor: pointer;
    }

.very-small {
  width: 0.6rem;
  height: 0.6rem;
}

.small {
  width: 1.6rem;
  height: 1.6rem;
}

.medium {
  width: 2.4rem;
  height: 2.4rem;
}

.large {
  width: 3.2rem;
  height: 3.2rem;
}
