.grid {
  margin-top: 7.2rem;
  grid-template-columns: 1fr repeat(7, auto)
}

@media (max-width: 1200px) {

.grid {
    grid-template-columns: 1fr repeat(4, auto)
}
  }

.boxed {
  grid-template-columns: 1fr repeat(4, auto)
}

@media (max-width: 1200px) {

.boxed {
    grid-template-columns: 1fr auto
}
  }
