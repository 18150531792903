.container {
  display: flex;
  flex-direction: column;
  max-width: 36rem;
  min-width: 36rem;
  height: 34.6rem;
}

.frame {
  width: 100%;
  height: 100%;
  border: 0;
}

.title {
  color: var(--color-text-primary);
  padding: 1.2rem 1.6rem;
  position: relative;
}

.title::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  height: 0.2rem;
  bottom: 0;
  background-color: var(--background-bottom-selector);
}

.selectorContainer {
  padding: 1.2rem 1.6rem;
}

.selectorContainer select {
  padding: 0.4rem 0.8rem 0.4rem 1.2rem;
  background: var(--surface-light);
  border-radius: 0.4rem;
  width: 100%;
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 1.6rem;
  color: var(--color-text-primary);
}

.wrapper {
  background-color: var(--core-backgroundTertiary);
}