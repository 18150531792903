.selectionContainer {
  background-color: grey;
  border-radius: 8px;
  padding: 16px 12px;
  margin-top: 16px;
}

.selection {
  background-color: white;
  padding: 16px;
  display: flex;
  flex-direction: row;
  border-radius: 8px;
}

.icon {
  margin-right: 8px;
}


.teamInfo {
  display: flex;
  flex-direction: column;
  flex: 1 1;
}

.teamInfo span {
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.6rem;

  /* color: var(--color-text-secondary); */

  /* TODO Fix color */
}

.centerAlign {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}


.codesContainer {
  margin-top: 16px;
}
.code {
  margin: 4px 0;
  font-size: 24px;
}

.oddsContainer {
  display: flex;
  flex-direction: row;
}

.oddsContainer span:first-child {
  flex: 1 1;
}
