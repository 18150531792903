.mainButton {
  text-align: left;
  background-color: transparent;
  color: var(--color-text-primary);
  display: flex;
  flex-direction: row;
  align-items: center;
  grid-gap: 0.8rem;
  gap: 0.8rem;
  font-size: 2.2rem;
  font-weight: 700;
  border: none;
}

.popup {
  display: flex;
  flex-direction: column;
  max-height: 40rem;
  background-color: var(--core-backgroundPrimary);
  overflow: auto;
  border-radius: 0.4rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
}

.button {
  text-align: left;
  background-color: var(--core-backgroundPrimary);
  color: var(--color-text-primary);
  display: flex;
  flex-direction: row;
  align-items: center;
  grid-gap: 0.8rem;
  gap: 0.8rem;
  font-size: 2.2rem;
  font-weight: 700;
  border: none;
  padding: 0.8rem;
}
