.container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.competition {
  color: var(--color-text-primary);
  max-width: 30rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden
}

@media (max-width: 1200px) {

.competition {
    max-width: 20rem
}
  }

@media (max-width: 480px) {

.competition {
    max-width: 10rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden
}
  }

.leftContainer {
  flex: 1 1;
  display: flex;
  align-items: center;
}

.rightContainer {
  display: flex;
}

.features {
  flex-direction: row;
  display: flex;
  flex: 1 1;
  align-items: center;
  margin-left: 0.8rem;
}

.verticalSeperator {
  margin: 0 0.4rem;
  width: 0.2rem;
  background-color: var(--color-text-secondary);
  height: 1.6rem;
}

.markets {
  color: var(--color-text-link);
  font-weight: bold;
  display: flex;
}

@media (max-width: 480px) {

.markets > span {
    display: none
}
  }


.icon {
  width: 2.4rem;
  height: 2.4rem;
}

.icon * {
  fill: var(--color-text-primary);
}


.timeMarket {
  display: flex;
  align-items: center;
  color: var(--color-text-primary);;
}

.time {
  display: flex;
  align-items: center;
}

.countdown {
  margin-right: 0.4rem;
  overflow: hidden
}

@media (max-width: 480px) {

.countdown {
    max-width: 7.8rem;
    min-width: 0;
    display: block;
    text-overflow: ellipsis;
    white-space: nowrap
}
  }

.live {
  display: flex;
  align-items: center;
}

.live svg {
  width: 43px;
  height: 16px;
}
