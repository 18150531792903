.container {
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.odds {
  display: flex;
}

.odds button {
  flex: 1 1;
}
.odds button:first-child {
  margin-right: 8px;
}