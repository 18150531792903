.grid {
  grid-template-columns: 1fr repeat(3, auto);
  margin-top: 4rem;
}

.exoticLabel {
  display: flex;
  flex-direction: column;
  margin: 1.6rem 0 0.8rem;
}

.exoticTitle {
  font-size: 1.2rem;
  font-weight: 600;
}
