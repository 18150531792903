.container {
  box-shadow: var(--shadow-c1-center);
  border-radius: 0.4rem;
  min-width: 42.4rem;
  padding: 0.8rem 1.2rem 1.2rem;
  background-color: var(--core-backgroundPrimary)
}
@media (max-width: 480px) {
  .container {
    border-radius: 0;
    min-width: 38.4rem
}
  }
