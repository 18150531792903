.container {
  box-shadow: 0 0.2rem 4px rgba(0, 0, 0, 0.08);
  border-radius: 0.4rem;
  overflow: hidden;
  scroll-snap-align: start;
  background-color: var(--color-white);
  padding: 1.2rem 1.6rem;
  width: 32rem;
  position: relative
}

.container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 0.4rem;
    background-color: #2a61b2;
  }

@media (max-width: 480px) {

.container {
    min-width: 100%
}
  }

.separator {
  height: 0.1rem;
  background-color: var(--border-primary);
  margin: 0.8rem 0;
}

.header {
  margin-bottom: 0.8rem
}

.header .foxTips {
    margin-left: auto;
    width: 4.6rem;
    height: 0.9rem;
  }

.content .comment {
    min-height: 3.6rem;
    text-overflow: ellipsis;
    overflow: hidden;

    /* autoprefixer: ignore next */
    display: -webkit-box; /* stylelint-disable-line value-no-vendor-prefix */
    margin: 0 auto;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical; /* stylelint-disable-line value-no-vendor-prefix */
    white-space: normal;
  }

.content p {
    padding: 0;
    margin: 0;
  }

.content .readmore {
    color: #3e818c;
    margin-top: 0.4rem;
    padding: 0;
  }

.readMoreEmpty {
  height: 2.8rem;
}

.track {
  display: flex;
  align-items: center;
  font-size: var(--font-size--label);
  margin: 0.8rem 0
}

.track .trackName {
    margin-left: 0.4rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

.track .status {
    margin-left: auto;
    text-align: right;
    font-weight: var(--font-weight--semi-bold);
  }

.priceContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  grid-gap: 0.8rem;
  gap: 0.8rem;
  padding: 0 0.8rem
}

.priceContainer .runnerDetails {
    flex: 1 1;
    overflow: hidden;
  }

.priceContainer .prices {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 0.8rem;
    gap: 0.8rem;
  }
