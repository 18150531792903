.infoContainer {
  padding-top: 1.6rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-gap: 0.8rem;
  margin-bottom: 1.2rem;
  font-size: var(--font-size--label)
}

@media (max-width: 1024px) {

.infoContainer {
    grid-template-columns: 1fr
}
  }

.containerBox {
  height: 16rem;
  background-color: var(--surface-moderate);
  padding: 1.6rem;
  overflow-y: scroll;
  color: var(--color-text-primary);
}

.containerBoxContent {
  max-width: 40rem;
}

.heading {
  font-weight: var(--font-weight--semi-bold);
  font-size: var(--font-size--header-2);
  margin-bottom: 0.8rem;
  color: var(--color-text-secondary)
}

.heading a {
    color: var(--color-secondary);
  }

.linkBold {
  font-weight: var(--font-weight--semi-bold);
  color: var(--color-text-primary);
}

.commentContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  white-space: normal;
}

.runnerDetails {
  display: flex;
  justify-content: space-between
}

.runnerDetails div {
    margin-right: 0.8rem
  }

.runnerDetails div span {
      word-wrap: break-word;
    }

.runnerDetail {
  display: grid;
  grid-template-columns: 4rem 1fr;
  grid-gap: 1.6rem;
  margin: 0.8rem 0 0;
}

.runnerDetailWin {
  display: grid;
  grid-template-columns: 4rem 1fr 1fr;
  grid-gap: 1.6rem;
  margin: 0.8rem 0 0;
}

.statsContent {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.selectionButtons {
  display: flex;
  flex-direction: row;
  width: 32rem;
  border-radius: 0.4rem;
  overflow: hidden;
  border: 0.1rem solid var(---border-primary);
  align-self: center;
  box-shadow: 0 0.2rem 0.4rem rgba(0, 0, 0, 0.08);
  height: 3.6rem;
}

.button {
  padding: 0.4rem 1.6rem;
  width: 8rem;
  border-radius: 0;
  border: none;
  background: none;
  color: var(--elements-background-tab-primary-active);
  background-color: var(--button-numpad)
}

.button:not(:last-child) {
    border-right: 1px solid var(---border-primary);
  }

.button.selected {
    background-color: var(--brand-background-tabPrimaryActive);
    color: var(--color-text-primary);
  }

.smallButton {
  padding: 0 0.8rem;
}

.filteredStats {
  margin-top: 1.6rem;
  display: flex;
  width: 32rem;
  align-self: center;
  justify-content: space-between;
  padding: 0 0.8rem 0 1.6rem;
}

.filteredStatsItem {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.featured {
  color: var(--elements-background-tab-primary-active);
  font-weight: var(--font-weight--bold);
  font-size: var(--font-size--display-1);
  margin-top: 0.8rem;
}

.label {
  font-weight: var(--font-weight--semi-bold);
}

.breakWord {
  white-space: normal;
}
