

.head2headContainer {
  padding: 16px
}

@media (max-width: 480px) {

.head2headContainer {
    padding: 0;
    padding-top: 1rem
}
  }

.noEvents {
  color: var(--color-text-secondary);
  font-size: 1.4rem;
  line-height: 1.6rem;
}

.head2headContainer h2 {
  color: var(--color-text-primary);
}

.headToHeadCard {
  margin-bottom: 10px;
}
