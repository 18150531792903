.container {
  margin: 0.8rem 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
  background: var(--border-primary)
}

@media (max-width: 480px) {

.container {
    background-color: transparent
}
  }

.race {
  text-decoration: none;
  color: var(--color-text-secondary);
  border-bottom: 1px solid var(--border-primary);
  border-right: 1px solid var(--border-primary);
  box-shadow: none !important
}

.race:last-child {
    border-bottom: 1px solid transparent;
  }

@media (max-width: 1024px) {

.race {
    border-bottom: none;
    border-right: none
}

    .race:last-child {
      border-bottom: none;
    }
  }

.results {
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: var(--color-text-secondary);
}

.desktop {
  display: flex;
  margin-left: 12rem
}

.desktop .grid {
    display: grid;
    border-radius: 0.4rem
  }

.desktop .grid > * {
      background-color: var(--surface-light);
      min-height: 6.8rem;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.4rem 0.8rem;
    }

.desktop .meetingColumn {
    display: flex;
    position: absolute;
    left: 0;
    flex-direction: column;
    width: 12rem;
    z-index: 2;
  }

.desktop .meetingWrapper:first-child {
      padding-left: 0;
    }

.desktop .meetingWrapper {

    padding-left: 1.6rem;
    background: var(--core-backgroundPrimary);
  }

.desktop .header {
    background-color: var(--background-table);
    min-height: auto;
    color: var(--color-white);
    padding: 0.8rem 0 0.7rem;
    font-weight: var(--font-weight--bold);
    font-size: var(--font-size--label);
    width: 100%;
    border-bottom: 1px solid var(--border-primary);
    border-right: 1px solid var(--border-primary);
  }

.desktop .blankHeader {
    color: var(--background-table) !important;
    height: 3.1rem;
  }

.desktop .trackContainer {
    font-size: var(--font-size--label);
    background-color: var(--core-backgroundPrimary);
    min-width: 12rem;
    display: flex;
    align-items: center;
  }

.desktop .track {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center
  }

.desktop .track > span {
      display: block;
    }

.desktop .track > .name {
      color: var(--color-text-secondary);
      font-weight: var(--font-weight--bold);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 10rem;
    }

.desktop .track > .country {
      color: var(--color-text-secondary);
    }

.desktop .race {
    font-size: var(--font-size--label);
    position: relative;
    min-width: 8rem
  }

.desktop .race.open {
      background-color: var(--core-backgroundPrimary);
    }

.desktop .meetingItem {
    min-height: 6.8rem;
    padding: 0.8rem 1.2rem 0.8rem 0
  }

.desktop .meetingItem:not(:first-child) {
      /* margin-top: 0.1rem; */
    }

.desktop .badges {
    position: absolute;
    left: 0.4rem;
    bottom: 0.4rem;
    line-height: 0;
  }

@media (max-width: 1024px) {

.desktop {
    display: none
}
  }

.mobile {
  display: none;
  position: relative
}

.mobile .list {
    margin: 0 -1.6rem;
  }

.mobile .meeting {
    background-color: var(--core-backgroundPrimary);
    padding: 1.6rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    border: 1px solid var(--border-primary);
    text-align: left;
    -webkit-appearance: none;
            appearance: none
  }

.mobile .meeting:hover {
      cursor: pointer;
    }

.mobile .track {
    font-size: var(--font-size--label);
    flex: 1 1;
    color: var(--color-text-secondary);
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-gap: 0.4rem;
    gap: 0.4rem
  }

.mobile .track > .name {
      font-weight: var(--font-weight--bold);
    }

.mobile .track > .country {
      color: var(--color-text-secondary);
    }

.mobile .track > .raceNumber {
      font-weight: var(--font-weight--bold);
    }

.mobile .secondary {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: var(--font-weight--bold);
    font-size: var(--font-size--label);
  }

.mobile .badges {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 -0.2rem
  }

.mobile .badges > * {
      margin: 0 0.2rem;
    }

.mobile .lozenge {
    margin-left: 0.4rem;
    width: 6.4rem;
    text-align: right;
  }

.mobile .race {
    padding: 0.4rem 1.6rem;
    background-color: var(--core-backgroundPrimary);
    opacity: 0.8;
    margin-bottom: 0.2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between
  }

.mobile .race .circle {
      width: 3.2rem;
      height: 3.2rem;
      background-color: var(--surface-light);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      font-weight: var(--font-weight--bold);
    }

.mobile .race .column {
      flex: 1 1;
      display: flex;
      flex-direction: column;
      margin: 0 0.4rem;
    }

.mobile .race .row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

.mobile .race .trackName {
      flex: 1 1;
      color: var(--color-text-secondary);
      font-weight: var(--font-weight--bold);
      display: block;
      font-size: var(--font-size--label);
    }

.mobile .race .prizeMoney {
      flex: 1 1;
      display: block;
      color: var(--color-grey--600);
      font-size: var(--font-size--label);
    }

.mobile .race .secondary {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

.mobile .race .time {
      color: var(--color-text-secondary);
    }

@media (max-width: 1024px) {

.mobile {
    display: block
}
  }

.noMeetingsAvailable {
  display: block;
  margin: 0.4rem 0;
  font-size: var(--font-size--label);
  color: var(--color-text-secondary);
}

.race:not(:empty):hover {
  cursor: pointer;
}
