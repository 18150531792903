.container {
  padding: 1.6rem;
  color: var(--content-tertiary);
  height: 100vh
}

@media (max-width: 480px) {

.container {
    padding: 1.2rem .8rem
}
  }

.card {
  margin-top: 12px;
}

.wrapper {
  display: flex;
  flex-wrap: wrap;
}

.main {
  flex: 1 1;
}

.noEvents {
  color: var(--color-text-secondary);
  font-size: 1.4rem;
  line-height: 1.6rem;
  padding-left: 1rem;
}

.widgets {
  margin-left: 12px;
  margin-bottom: 12px
}

@media (max-width: 1024px) {

.widgets {
    margin-left: 0;
    width: 100%
}
  }

@media (max-width: 1024px) {

.widget {
    margin-left: 0;
    width: 100%;
    max-width: 100%
}
  }


.main h2 {
  color: var(--content-tertiary)
}