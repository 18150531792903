.container {
  padding: 1.6rem 0 0.8rem;
  background-color: transparent;
  color: var(--color-text-tertiary);
  font-weight: bold;
  text-transform: uppercase;
  font-size: var(--font-size--header-3);
  line-height: var(--line-height--medium)
}
@media (max-width: 480px) {
  .container {
    padding-left: 1rem;
    padding-right: 1rem
}
  }

