.toggleSwitchContainer {
  height: 100%;
  display: flex;
  align-items: center;
  background-color: var(--surface-light);
  border-radius: 0.8rem;
  color: var(--color-white);
}

.toggleSwitchLabel {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  font-weight: var(--font-weight--semi-bold);
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none
}

.toggleSwitchLabel:hover {
    cursor: pointer;
  }

.toggleSwitchToggle {
  position: relative;
  background: var(--surface-moderate);
  width: 4.8rem;
  height: 2.4rem;
  display: block;
  border-radius: 2.4rem;
  transition: all 0.2s ease-in-out
}

.toggleSwitchToggle::before {
    content: '';
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    bottom: 0;
    left: 0.2rem;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background-color: var(--color-white);
  }

.toggleSwitchInput {
  display: none
}

.toggleSwitchInput:checked + .toggleSwitchToggle {
    background-color: var(--elements-background-tab-primary-active)
  }

.toggleSwitchInput:checked + .toggleSwitchToggle::before {
      left: auto;
      right: 0.2rem;
    }
