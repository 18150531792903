.container {
  width: 5.4rem;
  height: 3.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 8px;
  color: var(--content-secondary);
  box-sizing: border-box;
}

.dropDown {
  box-shadow: var(--shadow-b2-below-center);
  border-radius: .8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  pointer-events: none;
  font-weight: 700;
  font-size: 2rem;
  line-height: 2.4rem;
  color: var(--content-secondary);
  padding: 0.7rem;
}

.container select {
  border: none;
  -webkit-appearance: none;
          appearance: none;
  flex: 1 1;
  text-align: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  font-size: 0;
  border-radius: 8px;
  width: 100%;
  background-color: var(--core-backgroundPrimary);
}

.container option {
  font-size: 1.6rem;
}

.label {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  pointer-events: none;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: var(--content-secondary);
}

.loadingContainer {
  width: 2.4rem;
  height: 2.4rem;
  display: flex;
  align-items: center;
}
