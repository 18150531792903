.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 1.6rem;
  min-width: 25%;
  color: var(--color-text-primary);
}

.fraction {
  color: var(--color-text-primary);
  font-weight: var(--font-weight--semi-bold);
  margin-bottom: 0.4rem;
}

.position {
  background: var(--core-backgroundModerate);
  border-radius: 0.2rem;
  color: var(--color-text-primary);
  padding: 0 0.4rem;
  font-size: var(--font-size--caption);
  margin-bottom: 0.4rem;
  min-width: 3rem;
  text-align: center;
}

.time {
  margin-bottom: 0.4rem;
  color: var(--color-text-primary);
}

.margin {
  font-size: var(--font-size--caption);
}
