.sportMarket {
  padding: 10px 0;
  display: flex;
  align-items: center;
}

.sportMarket > div {
  flex: 1 1;
}

.oddsBtn {
  width: 100%;
}

.oddsBtn > span:nth-of-type(2) {
  font-weight: bold;
}