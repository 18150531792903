.content {
  padding: 1.6rem;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  grid-gap: 0.8rem;
  gap: 0.8rem
}

@media (max-width: 480px) {

.row {
    flex-direction: column
}
  }

.buttons {
  flex: 1 1;
  display: flex;
  flex-direction: row;
  grid-gap: 0.8rem;
  gap: 0.8rem;
  overflow: auto;
}

.button {
  flex-shrink: 0;
  font-size: var(--font-size--label);
  padding: 0.8rem
}

.button:focus {
    box-shadow: none !important;
  }

.foxTips {
  display: flex;
  flex-direction: row;
  align-items: center;
  grid-gap: 0.2rem;
  gap: 0.2rem;
  font-weight: 500;
  font-size: var(--font-size--body);
}

.flexEnd {
  align-self: flex-end;
}
