.toggleButtonLabelContainer {
  height: 32px;
  display: flex;
  margin-right: 8px;
}

.toggleButtonLabel {
  background-color: var(--surface-light);
  flex: 1 1;
  width: 100%;
  white-space: nowrap;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  font-weight: var(--font-weight--semi-bold);
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  padding: 0.8rem 1.6rem;
  border: 1px solid var(--border-primary);
}

.toggleButtonLabel:hover {
  cursor: pointer;
}

.toggleButtonToggle {
  position: relative;
  background: var(--surface-light);
  height: 2.4rem;
  font-weight: 400;
  display: block;
  border-radius: 4px;
  transition: all 0.2s ease-in-out;
}

.toggleButtonInput {
  display: none;
}

.toggleButtonInput:checked + .toggleButtonLabel {
  color: var(--color-text-inverse);
  font-weight: 600;
  background-color: var(--brand-background-togglesActive);
}
