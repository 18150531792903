.container {
  padding: 1.6rem;
  color: var(--content-tertiary)
}

@media (max-width: 480px) {

.container {
    padding: 0;
    padding-top: 1.6rem
}
  }

.card {
  margin-bottom: 1.1rem;
}

.noEvents {
  color: var(--color-text-secondary);
  font-size: 1.4rem;
  line-height: 1.6rem;
  padding-left: 1rem;
}
