.meetingType {
  grid-area: type;
  border-radius: 0.8rem;
  box-shadow: 0 2px 8px 1px rgba(0, 0, 0, 0.08);
  background-color: var(--color-grey--800);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  width: 4rem;
  height: 4rem
}
.meetingType::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 0.4rem;
  }
.meetingType.THOROUGHBRED::after {
    background-color: var(--racing_thoroughbred--100);
  }
.meetingType.HARNESS::after {
    background-color: var(--racing_harness--100);
  }
.meetingType.GREYHOUND::after {
    background-color: var(--racing_greyhound--100);
  }
