.clickable:hover {
  cursor: pointer;
}

.competitorDetails {
  display: flex;
  flex-direction: column;
  line-height: var(--line-height--small);
  font-size: var(--font-size--label);
  overflow: hidden;
}

.competitor {
  display: flex;
  flex-direction: row;
  grid-gap: 0.8rem;
  gap: 0.8rem;
  padding: 0.8rem 0
}

@media (max-width: 480px) {

.competitor {
    padding: 0.8rem
}
  }

.competitor.compact {
    padding: 0;
  }

.competitor.enforceMinHeight {
    min-height: 6.4rem;
  }

.competitorName {
  font-size: var(--font-size--body);
  font-weight: var(--font-weight--semi-bold);
  line-height: var(--line-height--large);
  color: var(--color-text-primary);
  text-overflow: ellipsis;
  overflow: hidden;
}

.scratched {
  opacity: 0.6
}

.scratched .competitorName {
    text-decoration: line-through;
  }

.silkContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.positionBox {
  width: 2.6rem;
  height: 1.6rem;
  border-radius: 0.2rem;
  font-size: 0.9rem;
  line-height: 1.6rem;
  font-style: normal;
  font-weight: bold;
  text-align: center;
  color: var(--color-text-secondary);
  margin: auto 0;
  flex-shrink: 0;
}

.position--1 {
  background: #f6ddad;
  color: var(--color-grey--600);
}

.position--2 {
  background: #e0e2f2;
  color: var(--color-grey--600);
}

.position--3 {
  background: #fec19f;
  color: var(--color-grey--600);
}

.position--4 {
  background: #fff;
  border: 0.1rem solid #bec3cd;
  color: var(--color-grey--600);
}

.runnerContainer {
  display: grid;
  grid-template-columns: 12rem auto;
  grid-gap: 0 0.8rem;
  gap: 0 0.8rem;
  color: var(--color-text-secondary);
}

.long {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin: 0.2rem 0 0;
}

.label {
  color: var(--color-text-secondary);
}

.scratchedTime {
  margin-left: 0.8rem;
}
