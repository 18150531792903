.container {
  position: relative;
}

.scrollContainer {
  display: flex;
  flex-direction: row;
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  -ms-scroll-snap-type: x mandatory;
      scroll-snap-type: x mandatory;
  scrollbar-width: none
}

.scrollContainer::-webkit-scrollbar {
    display: none;
  }

.scrollContainer.roundedCorners {
    border-radius: 0.4rem;
  }

.arrow {
  position: absolute;
  top: 0.4rem;
  color: var(--color-text-button-filters);
  background-color: var(--button-filters);
  border-radius: 50%;
  border: none;
  line-height: 0;
  transition: all 0.2s ease-in-out;
  visibility: hidden;
  padding: 0.2rem;
  width: 2.4rem;
  height: 2.4rem;
  z-index: 3
}

.arrow.visible {
    visibility: visible;
    opacity: 0.85;
  }

.arrow.left {
    left: 0.8rem;
  }

.arrow.right {
    right: 0.8rem;
  }

.arrow:hover {
    cursor: pointer;
    background-color: var(--button-filters-active);
    color: var(--color-text-button-filters-active);
  }

.containerShortForm {
  grid-column: 1 / 7;
  background-color: var(--color-grey--50)
}

@media (max-width: 1024px) {

.containerShortForm {
    grid-column-end: 3
}
  }

.scrollContainerShortForm {
  justify-content: center;
}

.containerShortFormBetType {
  grid-column: 1 / 5;
  background-color: var(--color-grey--50)
}

@media (max-width: 1024px) {

.containerShortFormBetType {
    grid-column-end: 3
}
  }
