.container {
  width: 100%;
  border-radius: 4px;
  position: relative;
  border: 1px solid var(--surface-light)
}

.container.hasPrefix::before {
      content: attr(data-prefix);
      position: absolute;
      left: 0.8rem;
      top: 50%;
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%);
      color: var(--color-text-primary);
    }

.input {
  width: 100%;
  border: none;
  border-radius: inherit;
  padding: 0.8rem;
  background-color: var(--core-backgroundPrimary);
  color: var(--color-text-primary);
}

.input::-webkit-inner-spin-button {
  -webkit-appearance: none;
          appearance: none;
}

.showPasswordButton {
  position: absolute;
  right: 0.8rem;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  padding: 0.4rem;
}

.icon {
  height: 2rem;
  width: 2rem;
}

.eyeContainer {
  display: flex;
  color: var(--color-text-primary);
}

/* Removes safari password icon in password type inputs */
.input[type='password']::-webkit-contacts-auto-fill-button,
.input[type='password']::-webkit-credentials-auto-fill-button {
  visibility: hidden;
  display: none !important;
  pointer-events: none;
  height: 0;
  width: 0;
  margin: 0;
}
