.button {
  font-size: var(--font-size--body);
  border-radius: 0.4rem;
  border: 0.1rem solid;
  transition: all 0.2s ease-in-out;
  padding: 0.8rem 1.6rem;
  font-weight: var(--font-weight--regular);
  display: inline-flex;
  justify-content: center;
  align-items: center
}

.button:disabled {
    opacity: 0.75;

    /* border: 1px solid; */
  }

.button:not(:disabled):hover {
    cursor: pointer;
  }

.theme--default {
  background-color: var(--button-primary);
  border-color: var(--button-primary);
  color: var(--color-text-button-primary)
}

.theme--default:not(:disabled):hover,
  .theme--default[data-selected='true'] {
    background-color: var(--button-primary-hover);
    border-color: var(--button-primary-hover);
    color: var(--color-text-button-alt);
  }

.theme--primary {
  background-color: var(--button-primary);
  border-color: var(--button-primary);
  color: var(--color-text-button-primary)
}

.theme--primary:not(:disabled):hover,
  .theme--primary[data-selected='true'] {
    background-color: var(--button-primary-hover);
    border-color: var(--button-primary-hover);
    color: var(--color-text-button-alt);
  }

.theme--secondary {
  background-color: var(--button-secondary);
  border-color: var(--border-secondary);
  color: var(--color-text-button-secondary)
}

.theme--secondary:not(:disabled):hover,
  .theme--secondary[data-selected='true'] {
    background-color: var(--button-secondary-hover);
    color: var(--color-text-button-secondary);
  }

.theme--transparentDark {
  background-color: transparent;
  border-color: var(--color-grey--600)
}

.theme--transparentDark:not(:disabled):hover,
  .theme--transparentDark[data-selected='true'] {
    background-color: var(--color-grey--600);
    border-color: var(--color-grey--600);
    color: var(--color-white);
  }

.theme--dark {
  background-color: var(--color-black);
  color: var(--color-white);
}

.theme--transparentLight {
  color: var(--color-white);
  border-color: var(--color-white);
  background-color: transparent;
  font-size: var(--font-size--body);
  border-radius: 0.4rem;
  border: 0.1rem solid;
  padding: 0 1.6em
}

.theme--transparentLight:not(:disabled):hover,
  .theme--transparentLight[data-selected='true'] {
    background-color: var(--color-white);
    border-color: var(--color-white);
    color: var(--color-secondary--200);
  }

.theme--none {
  background: none;
  border: none;
  padding: 0;
}

.size--default {
  height: auto;
}

.size--small {
  min-height: 2.4rem;
  padding: 0.2rem 0.8rem;
}

.size--medium {
  min-height: 3.2rem;
}

.size--large {
  min-height: 4.3rem;
  padding: 1.2rem 2.4rem;
}

.buttonContent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.iconContainer {
  display: flex;
}

.marginRight {
  margin-right: 0.6rem;
}

.marginLeft {
  margin-left: 0.6rem;
}
