.container {
  height: 100vh;
}

.filters {
  padding: 15px 0;
  margin: 0 1.6rem;
  border-top: var(--border-primary) 1px solid;
  background-color: var(--core-backgroundPrimary);
  border-radius: 0 0 4px 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08)
}

@media (max-width: 480px) {

.filters {
    padding: 15px 0;
    border-radius: 0;
    margin: 0 0 16px
}
  }

.card {
  margin-bottom: 1.2rem;
}

.headToHeadCard {
  margin-bottom: 8px;
}

.head2headContainer {
  padding: 16px
}

@media (max-width: 480px) {

.head2headContainer {
    padding: 0;
    padding-bottom: 1rem
}
  }


.noEvents {
  color: var(--color-text-secondary);
  font-size: 1.4rem;
  line-height: 1.6rem
}


@media (max-width: 480px) {


.noEvents {
    padding-left: 1rem
}
  }