.container {
  font-size: var(--font-size--label);
  background-color: var(--surface-moderate);
  border-radius: 0.4rem;
  flex-basis: 100%;
  line-height: 1.6;
  overflow: hidden;
  color: var(--color-text-primary)
}

@media (min-width: 1200px) {

.container {
    flex-basis: 50%
}
  }

.toggleButton {
  width: 100%;
  background: var(--surface-moderate);
  padding: 1.2rem;
  border-radius: 0.4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: none;
  font-size: var(--font-size--label);
  height: 4rem
}

.toggleButton.openState {
    border-bottom: 1px solid var(--color-grey--200);
    border-radius: 0.4rem 0.4rem 0 0;
  }

.toggleButton:focus:not(:disabled) {
    outline: none;
    box-shadow: none !important;
  }

.chevronButton {
  background: none;
  border: none;
  display: flex;
  align-items: center;
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
  align-self: flex-start;
  height: 1.5rem;
  color: var(--color-text-primary)
}

.chevronButton:hover {
    cursor: pointer;
  }

.chevronButton.expanded {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    transition: -webkit-transform 0.5s ease;
    transition: transform 0.5s ease;
    transition: transform 0.5s ease, -webkit-transform 0.5s ease;
  }

.section {
  border-bottom: 1px solid var(--border-primary);
  padding: 1.2rem
}

.section:last-of-type {
    border-bottom: none;
  }

.innerSection {
  max-width: 50rem;
}

.title {
  color: var(--color-text-primary);
  font-weight: var(--font-weight--semi-bold);
}

.dataItem {
  margin-left: 0.4rem;
}

.marginBottom {
  margin-bottom: 1.2rem;
}

.tableContainer {
  display: flex;
  align-items: flex-start;
}

.table {
  border-collapse: collapse;
  width: 100%;
  color: var(--content--primary)
}

.table th {
    font-weight: var(--font-weight--regular);
    text-align: left;
    border: none;
    padding: 0 1.6rem 0 0
  }

.table th.strongTableHeader {
      color: var(--content--primary);
      font-weight: var(--font-weight--semi-bold);
    }

.table td {
    width: 18%;
    color: var(--content--primary);
  }

.limitWidth {
  max-width: 30%;
}

.percentageBarContainer {
  flex-basis: 50%;
  display: flex;
  justify-content: space-around;
}

.filteredStatsItem {
  margin-top: -0.8rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.jockeyForm {
  display: flex;
  width: 100%;
  justify-content: space-between;
  grid-gap: 1.2rem;
  gap: 1.2rem
}

@media (max-width: 400px) {

.jockeyForm {
    flex-direction: column
}
  }

@media (max-width: 400px) {

.mobileHide {
    display: none
}
  }
