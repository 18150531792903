.container {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.flucContainer {
  position: relative;
  margin-right: 2rem;
}

.drift::before {
  content: '';
  position: absolute;

  /* width: 1rem;
  height: 10px; */
  right: -1.2rem;
  top: 0.4rem;

  /* background: #049B5E; */
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  border-top: 0.4rem solid transparent;
  border-bottom: 0.4rem solid transparent;
  border-right: 0.4rem solid var(--color-success);
}

.firm::before {
  content: '';
  position: absolute;

  /* width: 1rem;
  height: 10px; */
  right: -1.2rem;
  top: 0.4rem;

  /* background: #049B5E; */
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  border-top: 0.4rem solid transparent;
  border-bottom: 0.4rem solid transparent;
  border-right: 0.4rem solid var(--color-alert);
}
