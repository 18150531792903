.recentStartInfo {
  background-color: var(--core-backgroundModerate);
  display: flex;
  flex-direction: row;
  align-items: top;
  justify-content: space-between;
  border-radius: 0.4rem;
  font-size: var(--font-size--label);
}

.label {
  font-weight: var(--font-weight--semi-bold);
  color: var(--color-text-primary);
  min-width: 5.5rem;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ellipsis {
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 60%;
  color: var(--color-text-secondary);
}

.startInfo {
  width: 100%;
  display: flex;
}

.wrap {
  flex-wrap: wrap;
}

.startInfoSection {
  display: flex;
  flex-direction: column;
  grid-row-gap: 0.4rem;
  row-gap: 0.4rem;
  width: 25%;
  margin-bottom: 0.4rem;
}

.twoColumn {
  flex-basis: 50%;
}

.singleColumn {
  flex-basis: 100%;
}

.shortFormRunnerInfo {
  background-color: var(--core-backgroundModerate);
  padding: 1.2rem;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 0 0 0.4rem 0.4rem;
}
