.container {
  display: flex;
  flex-direction: row;
  overflow: scroll;
}

.wrapper {
  display: flex;
  flex-direction: row;
}

.item {
  margin-right: 0.5rem;
}