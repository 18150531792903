.button {
  position: relative;
  background-color: var(--button-odds);
  border-radius: 0.4rem;
  font-size: var(--font-size--body);
  font-weight: 600;
  padding: 1.2rem;
  width: 100%;
  min-width: 4.8rem;
  color: var(--color-text-button-odds);
  margin: none;
  border: 1px solid transparent
}

.button:hover {
    cursor: pointer;
  }

.button.selected {
    background-color: var(--button-odds-active);
    color: var(--color-text-button-odds-active);
    border: 1px solid var(--button-odds-active);
  }

.button:disabled {
    background-color: transparent;
  }

.button.flex {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: var(--font-size--label);
  }

.drift {
  color: #049b5e;
}

.firm {
  color: #e2215a;
}

.drift::before {
  content: '';
  position: absolute;

  /* width: 1rem;
  height: 10px; */
  bottom: -0.3rem;
  right: 2.5rem;

  /* background: #049B5E; */
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  border-top: 0.8rem solid transparent;
  border-bottom: 0.8rem solid transparent;
  border-right: 0.8rem solid #049b5e;
  -webkit-animation: firm-animation 1.5s;
          animation: firm-animation 1.5s;
}

.firm::before {
  content: '';
  position: absolute;

  /* width: 1rem;
  height: 10px; */
  top: -0.3rem;
  right: 2.5rem;

  /* background: #049B5E; */
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  border-top: 0.8rem solid transparent;
  border-bottom: 0.8rem solid transparent;
  border-right: 0.8rem solid #e2215a;
  -webkit-animation: drift-animation 1.5s;
          animation: drift-animation 1.5s;
}

@-webkit-keyframes firm-animation {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes firm-animation {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-webkit-keyframes drift-animation {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes drift-animation {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fixedWidth {
  width: 5.6rem
}

@media (max-width: 1024px) {

.fixedWidth {
    width: 8rem
}
  }

.favourite {
  position: absolute;
  bottom: -0.6rem;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  border-radius: 0.8rem;
  background-color: #f6b92c;
  padding: 0 0.8rem;
  font-weight: 600;
  font-size: var(--font-size--label);
  color: var(--color-text-primary);
}
