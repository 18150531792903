.select {
  -webkit-appearance: none;
          appearance: none;
  background-repeat: no-repeat;
  background-position: calc(100% - 0.8rem);
  background-size: 2rem;
  width: 100%;
  border-radius: 4px;
  padding: 0.8rem;
  padding-left: 0.5rem;
  border: 1px solid var(--surface-light);
  color: var(--color-text-primary);
  background-color: var(--core-backgroundPrimary);
}
