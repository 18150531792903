.container {
  padding: 16px;
  background: linear-gradient(90deg, #09203F 0%, #537895 100%);
  margin: 0 16px
}

@media (max-width: 480px) {

.container {
    margin: 0;
    padding: 16px
}
  }

.wrapper {
  max-width: 60rem;
  margin: 0 auto;
}

.scoreBoard {
  margin-top: 8px;
}