.container {
  position: relative;
  display: flex;
  align-items: center;
  color: var( --color-grey--200);
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
}

.border {
  background-color: var(--color-grey--100);
  flex: 1 1;
  height: 2px;
  margin-left: 4px;
}