.container {
  margin: 0 1.6rem;
  padding: 12px 24px;
  background-color: var(--core-backgroundPrimary);
  border-bottom-left-radius: 0.4rem;
  border-bottom-right-radius: 0.4rem;
  color: var(--color-text-primary);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08)
}

@media (max-width: 480px) {

.container {
    margin: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0
}
  }

.tabs {
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
}

.tab {
  font-size: var(--font-size--body);
  text-decoration: none;
  color: var(--color-text-tertiary);
  font-weight: 700;
  position: relative;
  white-space: nowrap;
  margin-right: 24px;
}

.selected {
  color: var(--color-text-primary);
}

.selected::after {
  position: absolute;
  content: '';
  left: 0;
  right: 0;
  bottom: -12px;
  height: 0.4rem;
  background-color: var(--color-text-primary);
  border-top-left-radius: 0.2rem;
  border-top-right-radius: 0.2rem;
}