.container {
  background: var(--core-backgroundPrimary);
  padding: 0 1.6rem 1.6rem
}

@media (max-width: 480px) {

.container {
    padding: 0 0.8rem 0.8rem
}
  }

.content {
  background: var(--surface-light);
  padding: 1.4rem;
  padding-top: 0
}

@media (max-width: 480px) {

.content {
    padding: 0.8rem
}
  }

.runnerDetails {
  display: flex
}

.runnerDetails div {
    margin-right: 0.8rem;
  }

.runnerDetail {
  display: grid;
  grid-template-columns: 5.6rem 1fr;
}

.marginBottom {
  margin-bottom: 1.2rem;
}

.shortFormRunnerInfo {
  background-color: var(--surface-light);
  padding: 1.2rem 0 0.4rem;
  display: flex;
  align-items: center;
  justify-content: space-between
}

@media (max-width: 370px) {

.shortFormRunnerInfo {
    flex-wrap: wrap
}
  }

.label {
  font-weight: var(--font-weight--semi-bold);
  color: var(--color-text-primary);
}

.greyLabel {
  color: var(--color-grey--400);
}

.blackType {
  color: var(--color-grey--800);
}

@media (max-width: 370px) {

.title {
    margin-bottom: 0.8rem;
    width: 100%
}
  }

.highlightSection {
  display: flex;
  align-items: center;
  width: 100%;
}

.highlightRelevantRace {
  font-weight: var(--font-weight--regular);
  font-size: var(--font-size--label);
  margin-left: auto;
  margin-right: 0.8rem;
}

.shortFormTabs {
  display: flex;
  justify-content: left;
  grid-gap: 2.4rem;
  gap: 2.4rem;
}

.shortFormTab {
  font-size: var(--font-size--header-1);
  text-decoration: none;
  color: var(--color-text-primary);
  font-weight: var(--font-weight--bold);
  padding: 1.2rem 0;
  position: relative;
  white-space: nowrap;
  width: -webkit-max-content;
  width: max-content
}

.shortFormTab.selected {
    color: var(--color-grey--700);
  }

.shortFormTab.selected::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 0.4rem;
    background-color: var(--color-grey--700);
  }

@media (max-width: 480px) {

.shortFormTab {
    padding: 0 0 1.2rem
}
  }

.minWidth {
  min-width: 100px
}

@media (max-width: 1320px) {

.minWidth {
    min-width: unset
}
  }

.divider {
  width: 100%;
  height: 0.1rem;
  background-color: var(--border-primary);
}

.recentStartsContainer {
  margin-bottom: 1.6rem;
}

.jockeyTrainerWorkoutsContainer {
  display: flex;
  grid-gap: 0.8rem;
  gap: 0.8rem;
  width: 100%;
  padding-top: 1.6rem
}

@media (max-width: 800px) {

.jockeyTrainerWorkoutsContainer {
    flex-direction: column;
    grid-gap: 1.2rem;
    gap: 1.2rem
}
  }
