.title {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: var(--color-grey--800);
}
.howTo {
  text-align: center;
  display: flex;
  flex-direction: column;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.6rem;
}
