.grid {
  grid-template-columns: 1fr repeat(4, auto);
}

.toteGrid {
  grid-template-columns: 1fr repeat(5, auto);
}

@media (max-width: 1200px) {
  .grid {
    grid-template-columns: 1fr auto;
  }
}
