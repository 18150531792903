.container {
  background-color: var(--core-backgroundPrimary);
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container svg {
  width: 2.4rem;
  height: 2.4rem;
}

.container svg > * {
  fill: var(--color-text-primary);
}