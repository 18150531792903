.container {
  margin: -1.6rem 0 0.8rem 0;
  padding: 0.4rem 0.8rem;
  display: flex;
  align-items: center;
  border-radius: 0.4rem;
  border: 1px solid var(--elements-background-tab-primary-active);
  border-top: none
}

.container span {
    margin-left: 0.4rem;
  }

.placed {
  background-color: var(--color-success);
  color: white;
}

.rejected {
  background-color: var(--color-alert);
  color: white;
}

.partial {
  background-color: var(--color-attention);
  color: white;
  border-top: 0.1rem var(--color-grey--100) solid;
}

.alert {
  background-color: var(--color-attention);
  color: white;
}
