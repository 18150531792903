.container {
  margin: 1.6rem 0;
}

.commentContainer {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
  background-color: var(--core-backgroundPrimary);
  padding: 1.6rem;
  border-radius: 0.4rem;
  max-width: 72.4rem;
}
