.progress {
  margin: 0.8rem;
  text-align: center;
}

.barOverflow {
  position: relative;
  overflow: hidden;
  width: 5rem;
  height: 2.5rem;
  margin-bottom: -14px;
}

.bar {
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  box-sizing: border-box;
  border: 0.4rem solid var(--button-numpad);
  border-bottom-color: var(--color-success);
  border-right-color: var(--color-success);
}
