.breadcrumbs {
  margin: 0 0 0.8rem;
  padding: 0;
  list-style: none;
  color: var(--color-text-primary);
}

.breadcrumb {
  display: inline-block;
  font-size: var(--font-size--caption);
  color: var(--color-text-primary)
}

.breadcrumb > .breadcrumbIcon {
    font-weight: var(--font-weight--bold);
    margin: 0 0.4rem;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
  }

.breadcrumb {

  &li {
    color: var(--color-text-primary);
  }
}

.breadcrumbLink {
  text-decoration: none;
  color: var(--color-text-primary)
}

.breadcrumbLink a {
    color: var(--color-text-primary);
  }