.bet {
  padding: 0.8rem;
  display: flex;
  background: var(--surface-light);
}

.betContent {
  width: 100%;
  margin-left: 0.8rem;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center
}
.header span {
    font-weight: var(--font-weight--semi-bold);
  }

.centerContent span {
    margin-right: 0.4rem;
  }

.centerContent svg {
    margin-right: 0.4rem;
  }
.bottomContent {
  display: flex;
  align-items: center
}
.bottomContent span {
    margin-right: 0.4rem;
  }

.odds {
  color: var(--color-text-primary);
}

.CLOSED {
  opacity: 0.2;
}

.eventClosed {
  color: var(--color-alert);
  z-index: 600;
  opacity: 1;
}

.drift {
  color: var(--color-success);
}

.firm {
  color: var(--color-alert);
}

.drift::before {
  -webkit-animation: firm-animation 1.5s;
          animation: firm-animation 1.5s;
}

.firm::before {
  -webkit-animation: drift-animation 1.5s;
          animation: drift-animation 1.5s;
}

.drift-arrow {
  width: 0;
  height: 0;
  border-left: 0.4rem solid transparent;
  border-right: 0.4rem solid transparent;
  border-bottom: 0.4rem solid var(--color-success);
}

.firm-arrow {
  width: 0;
  height: 0;
  border-left: 0.4rem solid transparent;
  border-right: 0.4rem solid transparent;
  border-top: 0.4rem solid var(--color-alert);
}
@-webkit-keyframes firm-animation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes firm-animation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-webkit-keyframes drift-animation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes drift-animation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
