.spriteContainer {
  width: 100%;
  height: 32px;
}

.container {
  width: 32px;
  height: 32px;
  display: inline-block;
  position: relative;
}

.silkContainer {
  position: relative;
  width: 64px;
  height: 64px; /* Intentional px as spritesheets are 64x64 and are not relative */
  overflow: hidden;
  flex-shrink: 0;
  display: block;
  -webkit-transform: scale(0.5);
          transform: scale(0.5);
  -webkit-transform-origin: top left;
          transform-origin: top left;
  text-align: left;
}

.small {
  -webkit-transform: scale(0.35);
          transform: scale(0.35);
  -webkit-transform-origin: 0 8px;
          transform-origin: 0 8px
}

.small.silkPosition {
    margin-top: -3.55rem;
  }

.silkSpritesheet {
  position: absolute;
}

.greyhoundSilkSpritesheet {
  position: absolute;
  width: 100%;
}

.defaultSilk {
  left: 0.95rem;
  width: auto;
}

.saddlecloth {
  position: relative;
  width: 100%;
  height: 100%;
  border: 2px solid var(--color-grey--50);
  font-size: 3.2rem;
  border-radius: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: var(--font-weight--bold);
  color: #312b2c
}

.saddlecloth.number1 {
    background-color: #bc0101;
    color: var(--color-white);
  }

.saddlecloth.number1A {
    background-color: #bc0101;
    color: var(--color-white);
  }

.saddlecloth.number2 {
    background-color: #fff;
    border-color: #b3b8c2;
  }

.saddlecloth.number2A {
    background-color: #fff;
    border-color: #b3b8c2;
  }

.saddlecloth.number3 {
    background-color: #1778a5;
    color: var(--color-white);
  }

.saddlecloth.number3A {
    background-color: #1778a5;
    color: var(--color-white);
  }

.saddlecloth.number4 {
    background-color: #f6b92c;
  }

.saddlecloth.number4A {
    background-color: #f6b92c;
  }

.saddlecloth.number5 {
    background-color: #468263;
    color: var(--color-white);
  }

.saddlecloth.number5A {
    background-color: #468263;
    color: var(--color-white);
  }

.saddlecloth.number6 {
    background-color: #312b2c;
    color: #f6b92c;
  }

.saddlecloth.number6A {
    background-color: #312b2c;
    color: #f6b92c;
  }

.saddlecloth.number7 {
    background-color: #f26601;
  }

.saddlecloth.number7A {
    background-color: #f26601;
  }

.saddlecloth.number8 {
    background-color: #ff828e;
  }

.saddlecloth.number8A {
    background-color: #ff828e;
  }

.saddlecloth.number9 {
    background-color: #5bc9cb;
  }

.saddlecloth.number9A {
    background-color: #5bc9cb;
  }

.saddlecloth.number10 {
    background-color: #701f84;
    color: var(--color-white);
  }

.saddlecloth.number10A {
    background-color: #701f84;
    color: var(--color-white);
  }

.saddlecloth.number11 {
    background-color: #a9b2b5;
    color: #bc0101;
  }

.saddlecloth.number11A {
    background-color: #a9b2b5;
    color: #bc0101;
  }

.saddlecloth.number12 {
    background-color: #c4d977;
  }

.saddlecloth.number12A {
    background-color: #c4d977;
  }

.saddlecloth.number13 {
    background-color: #a16722;
    color: var(--color-white);
  }

.saddlecloth.number13A {
    background-color: #a16722;
    color: var(--color-white);
  }

.saddlecloth.number14 {
    background-color: #8b0000;
    color: #f6b92c;
  }

.saddlecloth.number14A {
    background-color: #8b0000;
    color: #f6b92c;
  }

.saddlecloth.number15 {
    background-color: #f2e6d8;
    border-color: #ded2c4;
  }

.saddlecloth.number15A {
    background-color: #f2e6d8;
    border-color: #ded2c4;
  }

.saddlecloth.number16 {
    background-color: #c3d3eb;
    color: #f26601;
  }

.saddlecloth.number16A {
    background-color: #c3d3eb;
    color: #f26601;
  }

.saddlecloth.number17 {
    background-color: #001a40;
    color: var(--color-white);
  }

.saddlecloth.number17A {
    background-color: #001a40;
    color: var(--color-white);
  }

.saddlecloth.number18 {
    background-color: #022601;
    color: #f6b92c;
  }

.saddlecloth.number18A {
    background-color: #022601;
    color: #f6b92c;
  }

.saddlecloth.number19 {
    background-color: #022873;
    color: #bc0101;
  }

.saddlecloth.number19A {
    background-color: #022873;
    color: #bc0101;
  }

.saddlecloth.number20 {
    background-color: #bf0863;
    color: #f6b92c;
  }

.saddlecloth.number20A {
    background-color: #bf0863;
    color: #f6b92c;
  }

.saddlecloth.number21 {
    background-color: #d2c7fb;
    color: #001a40;
  }

.saddlecloth.number21A {
    background-color: #d2c7fb;
    color: #001a40;
  }

.saddlecloth.number22 {
    background-color: #20426a;
    color: var(--color-white);
  }

.saddlecloth.number22A {
    background-color: #20426a;
    color: var(--color-white);
  }

.saddlecloth.number23 {
    background-color: #c2ddc7;
    color: #468263;
  }

.saddlecloth.number23A {
    background-color: #c2ddc7;
    color: #468263;
  }

.saddlecloth.number24 {
    background-color: #577127;
    color: var(--color-white);
  }

.saddlecloth.number24A {
    background-color: #577127;
    color: var(--color-white);
  }

.bothContainer {
  position: relative;
  width: 100%;
  height: 100%
}

.bothContainer > .bothSaddleCloth {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 32px;
    height: 32px
  }

.bothContainer > .bothSaddleCloth > .saddlecloth {
      font-size: 1.8rem;
    }

.silkOutline {
  position: absolute;
  margin-left: 0.45rem;
  margin-top: -0.15rem;
}

.smallOutline {
  position: relative;
  -webkit-transform: scale(0.7);
          transform: scale(0.7);
  -webkit-transform-origin: -4px 19px;
          transform-origin: -4px 19px;
}
