.author {
  margin-bottom: 0.8rem
}

.author .foxTips {
    grid-area: logo;
    height: 100%;
    width: 4.8rem;
  }

.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: flex-start;
  grid-gap: 0.8rem;
  gap: 0.8rem
}

@media (max-width: 1024px) {

.grid {
    grid-template-columns: 1fr
}
  }

.comment {
  white-space: normal
}

@media (max-width: 1024px) {
    .comment.truncated {
      overflow: hidden;

      /* autoprefixer: ignore next */
      display: -webkit-box; /* stylelint-disable-line value-no-vendor-prefix */
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical; /* stylelint-disable-line value-no-vendor-prefix */
    }
  }

.competitors {
  display: grid;
  grid-template-columns: 1fr auto auto;
  grid-gap: 0.4rem;
  gap: 0.4rem;
}

.oddsButton {
  width: 100%;
}

.separator {
  grid-column: 1 / -1;
  margin: 0.8rem 0;
  height: 0.1rem;
  background-color: var(--border-primary);
}

.actions {
  display: flex;
  flex-direction: row;
  grid-gap: 0.8rem;
  gap: 0.8rem;
  grid-column: 1 / -1
}

.actions > * {
    flex: 1 1;
  }

.readmore {
  color: #3e818c;
  margin-top: 0.4rem;
  padding: 0;
  font-weight: 600;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.lozenge {
  border-radius: 0.2rem;
  padding: 0.2rem 0.4rem;
  font-size: var(--font-size--label);
  color: var(--color-text-primary);
  font-weight: 600;
}

.roughy {
  background-color: #e0e2f2;
}

.bestBet {
  background-color: #f6ddad;
}
