.betItem {
  overflow-y: hidden;
  opacity: 1;
  background-color: var(--core-backgroundModerate);
  border-radius: 0.4rem;
  padding: 1.2rem;
  box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.08);
  display: flex
}
.betItem:not(:last-child) {
    margin-bottom: 0.8rem;
  }
.icons {
  width: 20px;
  display: flex;
  flex-direction: column;
  margin-right: 4px;
  align-items: center;
}
.bottomIcons {
  flex: 1 1;
  display: flex;
  align-items: flex-end;
}

.detailsContainer {
  flex: 1 1;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  line-height: 1;
  color: var(--color-text-primary);
}

.icon {
  position: relative;
  margin-right: 10px;
}

.betType {
  flex: 1 1;
  font-weight: var(--font-weight--semi-bold);
  position: relative;
  left: 0.8rem;
}

.stake {
  font-size: var(--font-size--header-3);
  font-weight: var(--font-weight--semi-bold);
}

.removeButton {
  background: none;
  border: none;
  border-radius: 0.4rem;
  display: flex;
  padding: 0;
  margin-left: 1.2rem;
  color: var(--color-primary)
}

.removeButton:hover {
    cursor: pointer;
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }

.title {
  display: block;
  font-weight: var(--font-weight--semi-bold);
  font-size: var(--font-size--label);
  line-height: var(--line-height--small);
  color: var(--color-text-primary);
  position: relative;
  flex: 1 1;
}

.marketName {
  font-weight: var(--font-weight--regular);
  font-size: var(--font-size--label);
  line-height: var(--line-height--small);
  color: var(--color-text-primary);
}

.selectionsContainer {
  color: var(--color-text-secondary);
  display: flex;
  font-size: var(--font-size--header-3);
  flex-direction: column;
  line-height: 16px;
}

.selections {
  flex-grow: 1;
  margin: 4px 0;

}

.inputContainer {
  display: flex;
  justify-content: flex-end;
}
.input {
  max-width: 120px;
}

.price {
  font-size: var(--font-size--header-3) !important;
  min-width: 30%;
  display: flex;
  justify-content: flex-end;
  margin-left: 0.4rem;
  margin-right: 2.5rem;
}

.stakeInput {
  width: 100%;
}

.return {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  margin: 1.6rem 0.4rem 0;
  font-size: var(--font-size--header-3);
  color: var(--color-text-secondary)
}

.return span {
    margin-left: 0.4rem;
    color: var(--color-text-primary);
    font-weight: var(--font-weight--bold);
  }

.headerMessage {
  color: var(--color-alert);
}
