.deductions {
  grid-column: 2 / -1;
  display: flex;
  justify-content: space-between;
}

.label {
  display: flex;
  align-items: center;
  color: var(--color-text-secondary);
}

.noDeductions {
  grid-column: 2 / -1;
  display: flex;
  justify-content: flex-end;
}
