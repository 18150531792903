.container {
  padding: 1.6rem;
  color: var(--content-tertiary)
}


@media (max-width: 480px) {


.container {
    padding: 0
}
  }


.noEvents {
  color: var(--color-text-secondary);
  font-size: 1.4rem;
  line-height: 1.6rem;
}


.card {
  margin-top: 1rem;
}

.seperator {
  margin-top: .8rem;
}

.filters {
  padding: 1.5rem 0;
  margin: -1.6rem 0 1.6rem;
  border-top: var(--border-primary) 1px solid;
  background-color: var(--core-backgroundPrimary);
  border-radius: 0 0 4px 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08)
}

@media (max-width: 480px) {

.filters {
    padding: 1.5rem 0;
    border-radius: 0;
    margin: 0 0 1.6rem
}
  }