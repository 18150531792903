
.phoneNumber {
  font-weight: 700;
  font-size: 2rem;
  line-height: 2.4rem;
}
.fastCode {
  margin-top: 8px;
  margin-bottom: 25px;
}


.centerAlign {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}