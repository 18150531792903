.container {
  background-color: var(--core-backgroundPrimary);
  padding: .8rem 4.1rem 1.5rem;
  border-radius: 8px
}

@media (max-width: 480px) {

.container {
    padding: 0.9rem 1.7rem
}
  }

.breadCrumbs {
  display: flex;
  align-items: center;
  justify-content: center;
}

.teamsContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.team {
  display: grid;
}

.teamBadgeContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.teamBadge {
  width: 40px;
  height: 40px;
  box-shadow: var(--shadow-b2-below-center);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  margin-bottom: 11px;
  min-width: 0;
  background-color: var(--core-backgroundModerate);
}

.teamBadge svg {
  width: 32px;
  height: 32px;
}

.teamName {
  overflow: hidden;
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.6rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
  color: var(--color-text-primary);;
}

.eventSelect {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: .9rem;
}

.timeText {
  margin-right: .4rem;
}

.desktopTimeContainer {
  margin-top: 0.8rem;
  color: var(--color-text-primary);
  font-size: 1.2rem;
  line-height: 1.6rem;
  font-weight: var(--font-weight--semi-bold);
  display: flex;
  align-items: center;
  flex-direction: column
}

@media (max-width: 1024px) {

.desktopTimeContainer {
    display: none
}
  }

.mobileTimeContainer {
  display: none
}

@media (max-width: 1024px) {

.mobileTimeContainer {
    display: flex;
    justify-content: center;
    color: var(--color-text-primary);
    font-size: 1.2rem;
    line-height: 1.6rem;
    font-weight: var(--font-weight--semi-bold);
    margin-bottom: .9rem
}
  }

.live {
  margin-top: 8px;
}
