.parlays {
  padding: 0.8rem;
  background-color: var(--surface-light);
  display: flex;
  flex-direction: column;
  box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.08);
  border-radius: 0.4rem;
  color: var(--color-text-primary);
}

.heading {
  display: flex;
  align-items: center;
  color: var(--color-text-primary)
}

.heading span {
    margin-left: 0.8rem;
  }
.parlayGroup {
  margin-top: 2.4rem;
}

.groupHeading {
  margin-bottom: 0.8rem;
}

.parlayBets {
  background-color: var(--core-backgroundModerate);
  border-radius: 0.4rem
}

.parlayBets div {
    background-color: var(--core-backgroundModerate);
  }

.footer {
  margin-top: 0.8rem;
  display: flex;
  align-items: center;
  background-color: var(--background-betslip);
}

.seperator {
  border-bottom: 0.1rem solid var(--border-primary);
  margin: 0.8rem;
}

.bold {
  font-weight: var(--font-weight--semi-bold);
}

.currencyInput {
  max-width: 120px;
}

.potentialReturn {
  align-self: flex-end;
  margin-top: 0.8rem;
}
.accordionHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: var(--core-backgroundModerate);
  padding: 0.8rem;
  color: var(--color-text-primary);
}

.inputContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.accordionButton {
  background-color: var(--core-backgroundModerate);
  color: var(--color-text-primary);
  border: none;
  border-radius: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: var(--font-weight--bold)
}

.accordionButton:hover {
    cursor: pointer;
  }

.accordionButton:focus {
    box-shadow: 0 0 1px 2px rgb(0, 0, 0, 0.02) !important;
  }

.chevron {
  margin-right: 0.8rem;
}

.bets {
  display: none;
  margin: 0.8rem;
}

.expanded .chevron {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }

.expanded .bets {
    display: block;
  }

.description {
  margin: 0.4rem 0 0 3.2rem;
  font-size: var(--font-size--caption);
}
