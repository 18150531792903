.container {
  margin: 0 1.6rem;
  padding: 1.6rem 1.6rem 0;
  background-color: var(--core-backgroundPrimary);
  border-bottom-left-radius: 0.4rem;
  border-bottom-right-radius: 0.4rem;
  color: var(--color-text-primary);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08)
}


@media (max-width: 480px) {


.container {
    margin: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0
}
  }


.headerComponent {
  padding-bottom: 1.6rem;
}

.tabs {
  display: flex;
  flex-flow: row nowrap;
  grid-gap: 1.6rem;
  gap: 1.6rem;
}

.tab {
  font-size: var(--font-size--body);
  text-decoration: none;
  color: var(--color-text-tertiary);
  font-weight: 700;
  padding: 0.8rem 0;
  position: relative;
  white-space: nowrap;
  border-top-left-radius: 0.4rem;
  border-top-right-radius: 0.4rem
}

.tab:focus:not(:disabled) {
    box-shadow: none !important;
  }

.tab.selected {
    color: var(--color-text-primary);
  }

.tab.selected::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 0.4rem;
    background-color: var(--brand-background-tabPrimaryActive);
    border-top-left-radius: 0.2rem;
    border-top-right-radius: 0.2rem;
  }

.tab.mobileOnly {
    display: none
  }

@media (max-width: 1200px) {

.tab.mobileOnly {
      display: block
  }
    }

.tab.desktopOnly {
    display: block
  }

@media (max-width: 1200px) {

.tab.desktopOnly {
      display: none
  }
    }

.tabsPlaceholder {
  height: 40px;
  max-height: 40px;
  width: 100%;
  overflow: hidden;
}
