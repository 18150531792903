.container {
  background-color: var(--core-backgroundModerate);
  font-size: var(--font-size--label);
  padding: 1.2rem;
}

.titleContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
}

.title {
  font-weight: var(--font-weight--semi-bold);
  color: var(--color-text-primary);
  font-size: var(--font-size--header-3);
  margin-right: 0.4rem;
  margin-bottom: 0.4rem
}

.title div {
    color: var(--color-text-primary);
  }

.fands {
  color: var(--color-text-primary);
}

.ellipse {
  width: 0.8rem;
  height: 0.8rem;
  color: var(--color-text-primary);
}

.fast {
  color: var(--color-alert);
  margin-right: 0.4rem;
}

.slow {
  color: var(--color-medal--gold);
  margin-right: 0.4rem;
  margin-left: 0.8rem;
}

.displayContainer {
  display: flex;
  justify-content: space-around;
  margin: 1.2rem 0 1.6rem;
}

.gradientBar {
  height: 0.4rem;
  width: 100%;
  background: linear-gradient(
    90.08deg,
    var(--color-alert) 0.06%,
    var(--color-medal--gold) 36.73%,
    #ff5c00 65.54%,
    var(--color-alert) 99.94%
  );
  margin-top: -6.6rem;
  margin-bottom: 5rem;
  border-radius: 0.8rem;
}
