.popular {
  width: 400px;
  height: 150px;
  background-color: rgba(255,255,255,1);
}
.container {
  height: 150px;
  width: 100%;
  overflow: hidden;
  background-color: rgba(255,255,255,1);
  margin-bottom: 12px;
  display: flex;
  flex-direction: column;
}
.wrapper {
  margin: 20px;
  overflow: hidden;
  flex: 1 1;
}