.container {
  display: flex;
  flex-direction: column;
  background-color: var(--core-backgroundPrimary);
  padding: 0.8rem 1.6rem;
}

.betsContainer {
  margin: 0;
  padding: 0;
}

.teamsName {
  display: flex;
}

.footerLink {
  padding: 1.6rem 0;
  text-align: center;
  color: var(--color-text-link);
  cursor: pointer;
}
