.grid {
  grid-template-columns: 1fr repeat(2, auto);
  margin-top: 4rem
}

.grid.showBetTypeEnabled {
    grid-template-columns: 1fr repeat(3, auto);
  }

.timelineContainer {
  background-color: var(--core-backgroundPrimary);
  -webkit-filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.08));
          filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.08));
  border-radius: 4px;
  padding: 0.8rem 1.6rem;
  margin-bottom: 1.6rem;
}

.timeline {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative
}

.timeline::before {
    content: '';
    position: absolute;
    top: 0.6rem;
    left: 4rem;
    right: 4rem;
    height: 0.4rem;
    background-color: var(--surface-light);
    z-index: -2;
  }

.progress {
  content: '';
  position: absolute;
  top: 0.6rem;
  left: 4rem;
  right: 4rem;
  height: 0.4rem;
  background-color: var(--button-primary);
  z-index: -2;
  transition: all 0.3s ease;
}

.timelineItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-gap: 0.4rem;
  gap: 0.4rem;
  position: relative;
  width: 6.4rem;
  text-align: center
}

.timelineItem > span {
    font-size: var(--font-size--label);
  }

.circle {
  width: 1.6rem;
  height: 1.6rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--surface-light);
  color: var(--surface-light)
}

.circle.enabled {
    background-color: var(--button-primary);
    color: var(--color-text-button-primary);
  }

.flex {
  flex: 1 1
}

.flex.flexStart {
    display: flex;
    justify-content: flex-start;
  }

.flex.flexEnd {
    display: flex;
    justify-content: flex-end;
  }
