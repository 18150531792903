.container {
  margin-bottom: 8px;
  width: 100%;
  border: none;
  background: none;
  text-align: left;
  background-color: var(--core-backgroundPrimary);
  box-shadow: var(--shadow-c1-center);
}

.labelContainer {
  padding: 12px 16px;
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.6rem;
  color: var(--color-text-primary);
  display: flex;
  align-items: center;
  background-color: var(--surface-surface-secondary);
  position: relative;
  cursor: pointer;
}

.labelContainerExpanded::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 2px;
  background-color: #34b0cf;

  /* TODO change to a css var */
}

.label {
  flex: 1 1;
}

.expand {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: var(--core-backgroundPrimary);

  /* TODO replace with li/* Surface/surfaceLight */
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content {
  color: var(--color-text-primary);
  transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  height: 0;
  transition-duration: 222ms;
  overflow: hidden;
  visibility: hidden;
}

.expandedContent {
  height: auto;
  min-height: 0;
  transition-duration: 222ms;
  overflow: visible;
  visibility: visible;
}

.contentWrapper {
  padding: 12px;
  margin: 12px;
}
