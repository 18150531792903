.countdown {
  background-color: var(--surface-moderate);
  font-size: var(--font-size--label);
  color: var(--color-text-primary);
  font-weight: 600;
  border-radius: 0.2rem;
  padding: 0 0.4rem;
  height: 1.6rem;
  min-width: 4.8rem;
  display: inline-block;
  text-align: center;
}

.urgent {
  color: var(--color-white);
  background-color: var(--color-alert);
}

.hideLozenge {
  background-color: transparent
}

.hideLozenge.urgent {
    color: var(--color-alert);
  }
