.receipt {
  display: flex;
  flex-direction: column;
  padding: 2.4rem;
  width: 320px;
}

.closeButton {
  border: none;
  background: none;
  padding: 0;
  line-height: 0
}

.closeButton:hover {
    cursor: pointer;
  }

.heading {
  font-size: var(--font-size--display-1);
  font-weight: var(--font-weight--bold);
  align-self: center;
  margin-bottom: 1.6rem;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1.6rem
}

.heading span {
    margin-left: 5.4rem;
  }
.container {
  margin-bottom: 3rem;
  border: 1px solid var( --color-grey--600);
  border-radius: 4px;
}

.BETSTATUS_PAID {
  border-top: 3px solid var(--color-success);
}

.BETSTATUS_UNRESULTED {
  border-top: 3px solid var(--color-success);
}

.BETSTATUS_PARTIAL {
  border-top: 3px solid var(--color-success);
}

.BETSTATUS_REFUNDED {
  border-top: 3px solid var(--color-alert);
}

.BETSTATUS_CANCELLED {
  border-top: 3px solid var(--color-alert);
}

.BETSTATUS_REJECTED {
  border-top: 3px solid var(--color-alert);
}
.bet {
  padding: 0.8rem;
}

.betType {
  text-transform: uppercase;
}
.seperator {
  border-bottom: 0.1rem solid var(--color-grey--100);
}
.race {
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: var(--font-size--header-1);
  font-weight: var(--font-weight--semi-bold);
  width: 100%

}
.race svg {
    margin-right: 0.8rem;
  }

.competitor {
  display: flex;
  flex-direction: row;
  padding: 0.8rem 0.4rem;
  min-width: 250px
}

.competitor > *:not(:last-child) {
    margin-right: 0.8rem;
  }

.competitorDetails {
  display: flex;
  flex-direction: column;
  line-height: var(--line-height--small);
  font-size: var(--font-size--label);
  font-weight: var(--font-weight--light);
}

.competitorName {
  font-size: var(--font-size--header-1);
  font-weight: var(--font-weight--semi-bold);
  line-height: var(--line-height--large);
}

.silkContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.runner {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.actionButtons {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.betDetails {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  margin: 0 0.8rem 0.8rem 0;
  line-height: var(--line-height--small);
  font-size: var(--font-size--label)
}

.betDetails span {
    margin-bottom: 0.4rem;
  }

.bold {
  font-weight: var(--font-weight--semi-bold);
}