/* stylelint-disable selector-class-pattern, custom-property-pattern, declaration-block-no-duplicate-properties */

.combosContainer {
  display: flex;
  align-items: center;
  text-align: left;
  background: none;
  border: none;
  color: var(--color-text-primary);
  padding: 0;
}

.expandIcon {
  color: var(--color-text-primary);
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modalOverlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  top: 50%;
  left: 50%;
  min-height: 90%;
  -webkit-transform: translateY(-50%) translateX(-50%);
          transform: translateY(-50%) translateX(-50%);
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.title {
  font-weight: var(--font-weight--semi-bold);
}

.closeBtn {
  position: absolute;
  right: 10px;
  border: 0;
  background: transparent;
  cursor: pointer;
}


.modal {
  background-color: var(--core-backgroundContrast);
  border-radius: 4px;
  flex: 1 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.comboCard {
  display: flex;
  border-bottom: solid 1px var(--color-grey--200);
  margin: 0 20px;
  padding: 20px 12px 10px;
  color: var(--color-text-primary);
}

.noBorder {
  border-bottom: none;
}

.modal h2 {
  text-align: center;
  padding: 0;
  margin: 0;
  padding-bottom: 16px;
}

.cards {
  background-color: var(--surface-light);
  flex: 1 1;
}

.details {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  margin-left: 12px;
}
