.grid {
  flex: 1 1;
  border-radius: 0.4rem;
  background-color: var(--core-backgroundPrimary);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
  margin: 0 0 0.8rem;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  grid-gap: 0.8rem;
  gap: 0.8rem;
  padding: 1.2rem 1.6rem;
  border-bottom: 1px solid var(--surface-light)
}

.row:hover {
    cursor: pointer;
  }

.raceDetailContainer {
  flex: 1 1;
  display: flex;
  flex-direction: row;
  grid-gap: 0.8rem;
  gap: 0.8rem;
}

.icon {
  color: var(--color-text-primary);
}

.raceDetail {
  display: flex;
  grid-gap: 0.4rem;
  gap: 0.4rem;
  margin-bottom: 0.2rem;
  font-size: var(--font-size--label);
}

.raceName {
  color: var(--color-text-primary);
  font-weight: 600;
}

.raceNumber {
  color: var(--color-text-secondary);
}

.country {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--color-text-secondary);
  font-size: var(--font-size--caption);
}

.racePool {
  text-align: right;
}

.pool {
  color: var(--color-text-secondary);
}

.lozenge {
  display: flex;
  justify-content: center;
  margin-right: 1.6rem;
}

.countdown {
  width: 6.4rem;
  text-align: right;
  font-weight: 600;
}

.empty {
  padding: 0 1.6rem;
  color: var(--color-text-secondary);
}
