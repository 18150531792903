.container {
  padding: 1.6rem;
  color: var(--content-tertiary);
}

.container iframe {
  min-width: 100%;
  min-height: 100%;
  border: 0;
}
