/* stylelint-disable selector-class-pattern, custom-property-pattern, declaration-block-no-duplicate-properties */

.accordionHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--core-backgroundModerate);
  padding: 0.8rem;
  border-bottom: solid 1px var(--border-primary);
}

.accordionButton {
  background-color: transparent;
  color: var(--color-text-primary);
  border: none;
  border-radius: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: var(--font-weight--bold)
}

.accordionButton:hover {
    cursor: pointer;
  }

.accordionButton:focus {
    box-shadow: 0 0 1px 2px rgba(0, 0, 0, 0.02) !important;
  }

.chevron {
  margin-right: 0.8rem;
}

.bets {
  display: none;
}

.children {
  margin: 0.8rem;
}

.expanded .chevron {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }

.expanded .bets {
    display: block;
  }


.stakeInputContainer {
  background-color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: var(--core-backgroundModerate);
  padding: 0.7rem 1.7rem 0.7rem 1.2rem;
}

.label {
  color: var(--color-text-primary);
  font-size: var(--font-size--body);
  margin-right: 0.8rem;
  flex: 1 1;
  white-space:nowrap;
  font-weight: 600;
}

.addToAllInput {
  background-color: transparent;
  color: var(--color-gray--600);
  padding: 0.4rem 1.6rem;
  font-size: 1.6rem;
  height: 3.2rem;
}

.currencyInputContainer {
  max-width: 132px;
}

/* stylelint-enable */